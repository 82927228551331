import axios from "axios";
//const BASE_URL = "http://172.16.111.46:3001/";
//const BASE_URL = "http://localhost:3001/";
const BASE_URL = "https://nodegarage.io:4443/";

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});