import React from 'react'
import { FcTodoList } from 'react-icons/fc'

function CheckListRow(props) {
    const { listItem } = props

    return (
        <div className="checkListInsideFlexBox">
            <div className="checkListIconFlexElement"><FcTodoList /></div>
            <div className="flexElement25w">{listItem}</div>
        </div>
    )
}

export default CheckListRow