import React from 'react';
import useMobileDetect from 'use-mobile-detect-hook';

// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function SideImageCard(props) {

  const { Title, 
    SubTitle, 
    Image, 
    ImageAltText, 
    mobileWidth,
    desktopWidth,
    config } = props
  const detectMobile = useMobileDetect();

  return (
    <div style={{ width: config.detectMobile.isMobile() ? mobileWidth+"vw" : desktopWidth+"vw" }} >
      <Card sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className="whiteOnBlackBoxShadow">
          {detectMobile.isDesktop() ?
            <CardContent sx={{ flex: '0 0 auto' }}>
              <Typography component="div" variant="h4">
                {Title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                {SubTitle}
              </Typography>
            </CardContent> : undefined }
          {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
            Digital Ocean
        </Box> */}
        </Box>
        <CardMedia className="whiteOnBlackBoxShadow"
          component="img"
          sx={{ width: "65%", height: "95%" }}
          image={Image}
          alt={ImageAltText}
        />
      </Card>
    </div>
  );
}
