import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

function XxsBut(props) {

  const {
    color,
    bgColor,
    text,
    hoverColor,
    hoverBgColor,
    link,
    ext,
  } = props

  const navigate = useNavigate()

  const handleClick = () => {
    if(ext){
      window.open(link, '_blank')
    } else {
      navigate(link)
    }
  }
  return (
    <span>
      <style type="text/css">
        {`
          .btn-flat {
            background-color: ${bgColor};
            color: ${color};
          }
          .btn-flat:hover {
            background-color: ${hoverBgColor};
            color: ${hoverColor};
          }

          .btn-xxs {
            padding: .1rem 1rem;
            font-size: .7rem;
          }
       `}
      </style>

      <Button variant="flat" size="xxs"
        onClick={() => handleClick()}>
        {text}
      </Button>
    </span>
  )
}

export default XxsBut