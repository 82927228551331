import { useNavigate } from 'react-router-dom'
import { useAuth } from "../utils/Auth";

import { Button } from "react-bootstrap";

function LoginButtons() {

    const auth = useAuth()
    const navigate = useNavigate()

    const handleLogin = () => {
        auth.setSignInState(true)
        navigate('/login')
    }

    return (
        <>
            <Button type="button" className="btn whiteOnBlackBoxShadow py-0" variant="outline-light" 
                    onClick={handleLogin}
            >
                LOG IN
            </Button>
        </>
    );
}

export default LoginButtons;