import React, {useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from "react-bootstrap";

import { useAuth } from '../utils/Auth';
import { useConfig } from '../utils/Configuration';

import XxsBut from '../components/XxsBut';
import Logo from '../components/Logo';

import { Tooltip } from '@mui/material';

function UserDetails() {

    const auth = useAuth()
    const config = useConfig()
    const navigate = useNavigate()

    const [nodeId, setNodeId] = useState(auth.loggedIn.nodeId)

    const handleContinue = () => {
        config.setMenuSelection({
            ...config.setMenuSelection,
            link: "/disclaimer"
        })
        navigate('/disclaimer')
    }
   
    var initNodeId = auth.loggedIn.nodeId

    useEffect(() => {
        initNodeId = auth.loggedIn.nodeId
        setNodeId(initNodeId)
    }, [auth.loggedIn.nodeId])

    return (
        <div>

            <Logo />
            <div className="hp4em" />

            <div>
                welcome <span className="boldLightBlue">{auth.loggedIn.discord}</span>
            </div>

            <div className="h1em" />

            <Button type="button" variant="primary" size="lg" className="btn btn-lg"
                onClick={handleContinue}>
                CONTINUE
            </Button>

            <div className="h1em" />

            <div className="font-p6rem whiteOnWhiteBoxShadow"
                style={{ border: "1px solid blue", padding: "10px" }}>
                <div className={config.detectMobile.isMobile() ? "userInfoFlexBoxMobile" : "userInfoFlexBoxDesktop"} >
                    <div className="userPageEmpty" />

                    <div className='userPageTitle'>
                        Discord:&nbsp;
                    </div>

                    <div className='userPageInfo'>
                        <span className="boldLightBlue">{auth.loggedIn.discord}</span>
                    </div>

                    <div className='userPageTitle'>
                        Email:&nbsp;
                    </div>

                    <div className='userPageInfo'>
                        <span className="boldLightBlue">{auth.loggedIn.email}</span>
                    </div>

                    <div className="userPageEmpty" />
                </div>

                <div className="hp4em" />

                <div>
                    Node Id:&nbsp;&nbsp;
                    <span className="boldLightBlue">
                        <Tooltip title={nodeId}>
                            <div>
                                {nodeId !== "Not Found on Server" ? 
                                <span>
                                    {nodeId.substring(0, 20)}<span>...</span>
                                </span> : 
                                <XxsBut link="/node/add-update-node"
                                bgColor="blue" color="white" ext={false}
                                hoverBgColor="white" hoverColor="blue"
                                text="ADD NODE ID TO NODE GARAGE"
                                />
                                }
                            </div>
                        </Tooltip>
                    </span>
                </div>

            </div>

            <div className='h2rem' />

        </div >
    )
}

export default UserDetails