import { useState, useContext, createContext } from "react";
import useMobileDetect from 'use-mobile-detect-hook';

import DigitalOceanSignUp from '../images/DigitalOceanSignUp.png';
import AWSSignUp from '../images/aws-SignUp.png';
import GCPSignUp from '../images/gcp-SignUp.png';
import GcpConsole from '../images/gcp-Console.png';

const ConfigContext = createContext(null)

export const ConfigProvider = ({ children }) => {

    const detectMobile = useMobileDetect();

    const [versioning, setVersioning] = useState({
        currentVersion: "v1.4.0",
        nodectlVersion: "v1.8.1",
    })

    const [disclaimer, setDisclaimer] = useState({
        manualReview: false,
    })

    const [providerInfo, setProviderInfo] = useState({
        providerName: "",
        provider: "aws",
        title: "",
        url: "",
        url_text: "",
        image: undefined,
        image2: undefined,
        instance: "instance",
        rootUbuntu: "root",
    })

    const [subMenu, setSubMenu] = useState({
        dig: {
            expand: false,
            active: false,
            duration: 0
        },
        aws: {
            expand: false,
            active: false,
            duration: 0
        },
        gcp: {
            expand: false,
            active: false,
            duration: 0
        },
        tess: {
            expand: false,
            active: false,
            duration: 0
        },
    })

    const [menuSelection, setMenuSelection] = useState({
        title: "",
        link: ""
    })

    const populateProviderDetails = (link) => {
        if (link.includes("do")) {
            setProviderInfo({
                providerName: "Digital Ocean",
                title: "SETUP YOUR DIGITAL OCEAN ACCOUNT",
                url: "https://www.digitalocean.com/",
                url_text: "Digital Ocean Home Page",
                image: DigitalOceanSignUp,
                image2: undefined,
                provider: "digitalOcean",
                rootUbuntu: "root",
                instance: "droplet"
            })
        } else if (link.includes("aws")) {
            setProviderInfo({
                providerName: "Amazon Web Services",
                title: "SETUP YOUR AMAZON WEB SERVICES ACCOUNT",
                url: "https://aws.amazon.com/",
                url_text: "Amazon Web Services Home Page",
                image: AWSSignUp,
                image2: undefined,
                provider: "aws",
                rootUbuntu: "ubuntu",
                instance: "instance"
            })
        } else if (link.includes("gcp")) {
            setProviderInfo({
                providerName: "Google Cloud Platform",
                title: "SETUP YOUR GOOGLE CLOUD PLATFORM ACCOUNT",
                url: "https://cloud.google.com/",
                url_text: "Google Cloud Platform Home Page",
                image: GCPSignUp,
                image2: GcpConsole,
                provider: "gcp",
                rootUbuntu: "root",
                instance: "instance"
            })
        }
    }

    return (
        <ConfigContext.Provider value={
            {
                detectMobile, populateProviderDetails,
                versioning, setVersioning,
                menuSelection, setMenuSelection,
                providerInfo, setProviderInfo,
                disclaimer, setDisclaimer,
                subMenu, setSubMenu
            }
        }>
            {children}
        </ConfigContext.Provider>
    )
}

export const useConfig = () => {
    return useContext(ConfigContext)
}