import React, { useState, useEffect } from 'react'

import PageTitle from '../components/PageTitle'
import TopOfPageLink from '../components/TopOfPageLink.js'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { axiosPrivate } from "../api/axios";

import { styled } from "@mui/system";

import CircularProgress from '@mui/material/CircularProgress';
import { ImPointUp } from 'react-icons/im';
import { useAuth } from '../utils/Auth';
import { useConfig } from '../utils/Configuration';

export default function Faq() {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [answer, setAnswer] = useState("empty");


    const StyledAutocomplete = styled(Autocomplete)({
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            // Default transform is "translate(14px, 20px) scale(1)""
            // This lines up the label with the initial cursor position in the input
            // after changing its padding-left.
            transform: "translate(34px, 20px) scale(1);",
            color: "rgba(255, 255, 0, .5)",
        },
        "& .MuiAutocomplete-inputRoot": {
            color: "white",
            backgroundColor: "#201e20",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                // Default left padding is 6px
                paddingLeft: 18
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "blue"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "green"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "pink"
            },

        },
    });

    const auth = useAuth();
    const config = useConfig();

    const loading = open && options.length === 0;

    async function getQuestions() {
        return new Promise((resolve, reject) => {

            axiosPrivate.post('api/getQuestions', {
                djlla: auth.djlla,
                token: auth.token
            })
                .then(function (response) {
                    if (response.data) {
                        // retrieve user successful
                        resolve(response.data.data)
                    } else {
                        reject(false)
                    }
                }).catch(function (error) {
                    reject(false)
                })
        })
    }

    var questions = undefined

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                questions = await getQuestions()
                setOptions([...questions]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
    }, [answer]);

    return (
        <>
            <div id="faqTop">
                <PageTitle title="FREQUENTLY ASKED QUESTIONS" />
                <div className='h1em' />
                <div className="textAlignLeftPad w-65">

                    <div>
                        You have a lot of questions, we will try to <span className="boldLightBlue">answer</span> them! You go
                        through the list of questions below, or attempt to search for your question using the form below.
                    </div>

                    <div className='h2em' />

                    <div className='font-p3em'>Click top edge of box to type in search when you have the input cursor (I).  
                    When you start typing, the autofill box will open, and you may need to click again to enter input mode.</div>
                    <div className='hp3em' />
                    <StyledAutocomplete
                        onChange={(event, value) => setAnswer(value.answer)}
                        autoSelect
                        id="faq-question-answers"
                        sx={{ width: "64vw" }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false)
                        }}
                        isOptionEqualToValue={(option, value) => option.answer === value.answer}
                        options={options}
                        loading={loading}
                        
                        renderInput={(params) => (
                                <div className="h1em">
                                <TextField fullWidth
                                    {...params}
                                    label={ config.detectMobile.isMobile() ? "Select or Type" : "Click to Select for Type a Question" }
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                                </div>
                        )}
                    />

                    <div className={ config.detectMobile.isMobile() ? 'h3em' : 'h2em' } />

                    {answer === "empty"
                        ?
                        <span className="colorLightBlue font-p7em">
                            Ask a question above
                            <span className="colorWhite">
                                {' '}<ImPointUp size={30} />
                            </span>
                        </span>
                        :
                        <div className="faqAnswer">
                            {answer}
                        </div>
                    }

                </div>
            </div>
            <TopOfPageLink hash="#faqTop" />
        </>
    );
}