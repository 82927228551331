import React from 'react'
import { ReactComponent as NGLogo } from '../images/NgLogoSvg.svg';

function LogoWithTitle(props) {
    const { text,fontClass, logoHeight } = props
    return (
        <div className="basicCardTitleDarkGray textShadowWhite">
            <NGLogo style={{ height: logoHeight }} />
            &nbsp;&nbsp;
            <span className={fontClass}>{text}</span>
        </div>
    )
}

export default LogoWithTitle