import { useConfig } from "../../utils/Configuration";
import CodeSnippet from '../../components/CodeSnippet';
import PageTitle from "../../components/PageTitle";
import TopOfPageLink from "../../components/TopOfPageLink";
import { HashLink } from 'react-router-hash-link';

import { Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

function P12Backup() {

    const config = useConfig()
    const configurationState = config.providerInfo

    return (
        <>
            <div id="p12backTop">
                <PageTitle title="BACKUP AND RESTORE P12" type={1} />

                <div className="textAlignLeftPad w-65">

                    <div className='hp6em' />

                    <div className="textAlignLeftPad w-65">
                        <div className="font-p5em">
                            Quick Link Jump To<br />
                            <HashLink smooth to="#backupHash">BACKUP P12</HashLink><br />
                            <HashLink smooth to="#restoreHash">RESTORE P12</HashLink><br />
                        </div>
                    </div>

                    <div className="h1em" id="backupHash" />

                    <PageTitle title="BACKUP P12" type={2} />

                    <div className="h1em" id="restoreHash" />

                    <PageTitle title="RECOVERY P12" type={2} />

                </div>
                <TopOfPageLink hash="#p12backTop" />
            </div>

        </>

    );
}

export default P12Backup;








