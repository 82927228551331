
function buildLeftNavigationText(isMobile){
    if(isMobile){
        return buttonListMobile
    } 
    return buttonListDesktop
}

const buttonListDesktop = {
    "Return Home": "/",
    "Welcome to TestNet": "/welcome",
    // "New Installation": "/new-install",
    "Build your server YouTube series": "/youtube",
    //"Upgrade Tessellation": "/tessellation/upgrade",
    "spacer1": "",

    "nodectl installation": "/nodectl/install",
    // "nodectl usage": "/nodectl/usage",
    //"Backup your P12": "/docs/p12backup",
    "Recover Node": "/docs/recovery",
    "Constellation Document Hub": "/docs/doc-index",
    "DO Brick Procedure": "/docs/do-troubleshoot",
    "spacer2": "",

    "Setup rewards": "/rewards",
    "Request NodeId Token": "/node/nodeid-cert",
    "Update Node Information": "/node/add-update-node",
    "FAQ - Frequently Asked Questions": "/docs/faq",
    "Review disclaimer": "/disclaimer"
}

const buttonListMobile = {
    "Home": "/",
    "spacer1": "",
    "Welcome": "/welcome",

    "spacer3": "",
    "YouTube": "/youtube",

    "nodectl": "/nodectl/install",
    // "nodectl use": "/nodectl/usage",
    "p12 backup": "/docs/p12backup",
    "Recover Node": "/docs/recovery",
    "Doc Hub": "/docs/doc-index",
    "DO TS": "/docs/do-troubleshoot",
    "spacer5": "",

    "Rewards": "/rewards",

    "spacer10": "",

    "Request Token": "/node/nodeid-cert",
    "spacer11": "",
    "Update Acct": "/node/add-update-node",
    "spacer12": "",
    "FAQ": "/docs/faq",
    "spacer13": "",
    "Disclaimer": "/disclaimer"
}

module.exports.buildLeftNavigationText = buildLeftNavigationText;