import React from "react";
import { Media, Player, controls } from 'react-media-player'

import { useConfig } from "../utils/Configuration";
const { PlayPause, MuteUnmute } = controls

function YouTube(props) {

    const config = useConfig()
    const { embedId, seriesNo, colorWhiteBlue, videoTitle, skip } = props;

    let boldClass, colorOpposite, boldOpposite

    if (colorWhiteBlue === "colorWhite") {
        boldClass = "boldLightBlue"
        colorOpposite = "colorLightBlue"
        boldOpposite = "boldWhite"
    } else {
        boldClass = "boldWhite"
        colorOpposite = "colorWhite"
        boldOpposite = "boldLightBlue"
    }


    let blurb = ""
    if (seriesNo > 0 || seriesNo) {
        blurb = <span>You may access our YouTube video titled;{' '}
            <span className="websiteMainFont">{videoTitle}</span>, for a detailed visual learning experience</span>
    } else {
        blurb =
            <span>If you are a visual learner, you can watch the{' '}
                <span className="boldLightBlue">
                    YouTube
                </span> series
            </span>
    }

    return (
        <>
            <div className={colorWhiteBlue}>
                <div>
                    {!skip && blurb}
                    {!skip && <span>
                        . You may also
                        follow <span className={boldClass}>step by step</span> instructions
                        {' '}using the tutorial below

                        for <span className={boldClass}>Apple/Linux</span> or <span className={boldClass}>Windows</span>.
                    </span>}
                    {' '}It is highly <span className="boldRed">recommended</span> to watch the entire series
                    {(seriesNo > 0 || seriesNo) && <span>, from the beginning</span>}.
                </div>

                <div className="h1em" />

                <div className={colorOpposite}>SSH Key Generation Series</div>

                {(seriesNo > 0 || seriesNo) && <span>Video {seriesNo}</span>}

                <span className={colorOpposite}>
                    {' '} by < span className={boldOpposite} > NetMet</span >.
                    < div className="font-p6em websiteMainFont" >
                        Please like and subscribe to support NetMet's work.
                    </div >
                </span>
            </div>
            <Media>
                { /* video-responsive */ }
                <div className={config.detectMobile.isMobile() ? "video-container" : ""}>
                    <div className="media">
                        <div className="media-player">
                            <Player src={`http://www.youtube.com/embed/${embedId}`} />
                        </div>
                        <div className="media-controls">
                            <PlayPause />
                            <MuteUnmute />
                        </div>
                    </div>
                </div>
            </Media>
        </>
    );

}

export default YouTube;