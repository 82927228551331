import React from "react";

import { HashLink } from 'react-router-hash-link';
import { useConfig } from "../../../../utils/Configuration";

import { Button, Badge, Tabs, Tab, Accordion } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

import PageTitle from "../../../../components/PageTitle";
import NextButtonCloudService from "../../../../components/NextButtonCloudService";
import TopOfPageLink from "../../../../components/TopOfPageLink";
import CodeSnippet from "../../../../components/CodeSnippet";

import DigitalOceanImageMac from "./DigitalOceanImageMac";
import DigitalOceanImageWin from "./DigitalOceanImageWin";

import { ImPointDown, ImPointUp } from 'react-icons/im';
import { FiFrown } from 'react-icons/fi';
import { MdKeyboardReturn } from 'react-icons/md';

import DropletTS01 from '../../../../images/DropletTS-01.png';
import DropletTS02 from '../../../../images/DropletTS-02.png';
import DropletTS03 from '../../../../images/DropletTS-03.png';
import DropletTS04 from '../../../../images/DropletTS-04.png';
import DropletTS05 from '../../../../images/DropletTS-05.png';
import DropletTS06 from '../../../../images/DropletTS-06.png';
import DropletTS07 from '../../../../images/DropletTS-07.png';
import DropletTS08 from '../../../../images/DropletTS-08.png';
import DropletTS09 from '../../../../images/DropletTS-09.png';
import DropletTS10 from '../../../../images/DropletTS-10.png';
import DropletTS11 from '../../../../images/DropletTS-11.png';
import DropletTS12 from '../../../../images/DropletTS-12.png';
import DropletTS13 from '../../../../images/DropletTS-13.png';
import DropletTS14 from '../../../../images/DropletTS-14.png';
import DropletTS15 from '../../../../images/DropletTS-15.png';
import DropletTS16 from '../../../../images/DropletTS-16.png';
import DropletTS17 from '../../../../images/DropletTS-17.png';
import DropletTS18 from '../../../../images/DropletTS-18.png';
import DropletTS19 from '../../../../images/DropletTS-19.png';
import DropletTS20 from '../../../../images/DropletTS-20.png';
import DropletTS21 from '../../../../images/DropletTS-21.png';
import DropletTS22 from '../../../../images/DropletTS-22.png';
import DropletTS23 from '../../../../images/DropletTS-23.png';
import DropletTS24 from '../../../../images/DropletTS-24.png';
import AccordionBody from "react-bootstrap/esm/AccordionBody";



function DigitalOceanTS() {

    const config = useConfig()

    let nextLink = "/do/launch-droplet"
    let nextName = "Digital Ocean launch droplet"

    return (
        <>
            <div style={{ width: "72vw" }} id="doNodeTop">
                <PageTitle title="TROUBLESHOOT DIGITAL OCEAN NODE" type={1} />
                <div className='h1em' />
                <div className="textAlignLeftPad w-65">

                    <div className="h1em" />
                    <PageTitle title="Unable to Access Node After Reboot" type={2} />

                    <div className="font-p5em">
                        Quick Link Jump To<br />
                        <HashLink smooth to="#step1_image">EASY FIX 1</HashLink><br />
                        <HashLink smooth to="#step2_image">START PROCESS 2</HashLink><br />
                        <HashLink smooth to="#step3_image">BACKUP FILES 3</HashLink><br />
                        <HashLink smooth to="#step4_image">START RESTORE 4</HashLink><br />
                        <HashLink smooth to="#step5_image">INSTALL NODECTL AND TESSELLATION 5</HashLink><br />
                        <HashLink smooth to="#step6_image">RECOVER FILES</HashLink><br />
                        <HashLink smooth to="#step7_image">RESTART OUR NODE 7</HashLink><br />
                    </div>

                    <div className="h1em" />

                    <div>
                        There are times when you might decide to issue a {' '}
                        <span className="boldLightBlue">sudo apt -y dist-upgrade</span>.  Usually, this is
                        {' '}<span className="boldRed">not</span>{' '}
                        recommended, unless you <span className="boldGreen">really</span> know what you are
                        doing.
                    </div>

                    <div className="h1em" />

                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <span className="boldRed13f">EXPAND FOR DETAILS ON DIST-UPGRADE ISSUES</span>
                            </Accordion.Header>
                            <Accordion.Body style={{ backgroundColor: "#131327" }}>
                                <div className="colorLightBlue">
                                    It is recommended you wait for more advanced users to vet out the
                                    results of a <span className="boldWhite">sudo apt -y dist-upgrade</span>. Before
                                    any type of upgrade, these types of actions are best suited for a maintenance window,
                                    during a MainNet or TestNet restart or upgrade, etc.
                                </div>
                                <div className='h1em' />

                                <div>
                                    <span className="boldRed">But</span>,{' '}even when you are an experienced
                                    System's Engineer, this can be a dangerous process!  <span className="boldLightBlue">In fact</span>,
                                    a <span className="boldLightBlue">apt dist-upgrade</span> is
                                    generally not recommended as a routine procedure.
                                </div>

                                <div className='h1em' />

                                <div className="colorLightBlue">
                                    <span className="boldWhite">Case Example</span>: The VM that you are running your Node on
                                    is under high CPU load.  Maybe, there is a process that has gone bad, the
                                    network is unstable, or being stress tested.  Are any of these (or other) current
                                    network statuses causing high CPU load?  This situation may cause a main system
                                    component or kernel update to become <span className="boldWhite">corrupted</span>.
                                </div>

                                <div className='h1em' />

                                After the <span className="boldLightBlue">dist-upgrade</span> you <span className="boldLightBlue">reboot</span>.
                                Your Node reboots, you lose access to it <span className="colorLightBlue">(as you normally should)</span> and then when
                                you try to reconnect after a minute <span className="colorLightBlue">(or few minutes)</span> you find you cannot access
                                your Node!

                                <div className='h1em' />

                                <div className="colorLightBlue">
                                    This is why a <span className="boldWhite">dist-upgrade</span> should be performed
                                    at distinct times, only when necessary, and after a vetting process has taken place.
                                </div>

                                <div className='h1em' />

                                If you decide you <span className="boldLightGreen">must</span> do
                                a <span className="boldLightBlue">dist-upgrade</span>, here are some recommended steps:
                                <ol>
                                    <CodeSnippet snipFontSize=".5em" code="sudo nodectl leave" />
                                    <CodeSnippet snipFontSize=".5em" code="sudo nodectl stop" />
                                    Wait 50 seconds
                                    <CodeSnippet snipFontSize=".5em" code="sudo apt update" />
                                    <CodeSnippet snipFontSize=".5em" code="sudo apt upgrade" />
                                    <CodeSnippet snipFontSize=".5em" code="sudo apt dist-upgrade" />
                                </ol>

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className='h1em' />

                    Returning to the issue that brought us to this page...

                    <div className='h1em' />

                    Let's start the process of attempting to recover our Node.  It
                    may be good to know that your <span className="boldLightBlue">Node</span> can be
                    deemed an <span className="boldLightBlue">ephemeral</span> device.  We only need to have backups
                    of <span className="boldLightBlue">two</span> main files to recovery our system fully.

                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step1_image"><Badge bg="success">STEP ONE</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Eliminate a firewall issue.</div>
                    <div className='hp8em' />

                    Make sure your local <span className="colorLightBlue">(home/office)</span> IP address <span className="boldRed">hasn't changed</span>
                    {' '}since your last reboot.
                    <div className='hp8em' />

                    <HashLink to="/do/firewall#troubleshoot">
                        <NextButtonCloudService nextLink="/do/firewall#troubleshoot" nextName="DIGITAL OCEAN FIREWALL SETUP" skipNext={true} />
                    </HashLink>
                    <div className='hp8em' />

                    Do <span className="boldRed">NOT</span> start over with your firewall settings or Node creation.  Instead, review
                    the above <ImPointUp size={25} /> section of Node Garage for further details on how to review and update your
                    firewall, <span className="boldLightGreen">if needed</span>.  Since we already setup our <span className="boldLightBlue">firewall</span>, the link
                    will automatically skip down to the proper section that starts with:
                    <CodeSnippet code="Now comes a little harder section." />

                    <div className='h1em' />
                    Attempt to access your Node again if you needed to update your IP address.  If you need a little help remembering
                    how to access your Node, see the link below <ImPointDown size={25} />
                    <div className='hp8em' />
                    <NextButtonCloudService nextLink="/access-vm" nextName="ACCESS VM" skipNext={true} />

                    <div className="h1em" />

                    If successful, you are <span className="boldLightGreen">done</span> and <span className="boldLightGreen">back
                        in business</span>.  If <span className="boldRed">not</span>
                    {' '} continue to step <span className="boldLightBlue">2</span>.

                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step2_image"><Badge bg="success">STEP TWO</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Place Droplet in recovery mode.</div>
                    <div className='hp8em' />

                    Unfortunately, the easy fix <span className="boldLightBlue">step 1</span> did not work, and you made it here
                    {' '} <FiFrown size={35} />. With a little patience, we can get our Node back up quickly.

                    <div className='h1em' />

                    In this section, we will place our <span className="boldLightBlue">Droplet</span> in <span className="boldLightBlue">recovery</span>
                    {' '}mode, verify if we can recovery our <span className="boldLightBlue">p12</span> file and our
                    {' '}<span className="boldLightBlue">node service configuration file</span>.  We will
                    then <span className="boldLightBlue">backup</span> these files, <span className="boldLightBlue">rebuild</span> our
                    Node using the existing SSH Keys, Node criteria, and firewall settings.  Finally, we will turn our Droplet back into
                    a <span className="boldLightBlue">Node</span>, install <span className="boldLightBlue">Tessellation</span> and
                    recovery our backed-up files.  The <span className="boldLightBlue">final</span> result will be a {' '}
                    <span className="boldLightBlue">new</span> node with all your <span className="boldLightBlue">old</span> settings in place.

                    <div className='h1em' />

                    <span className="boldOrange">Let's begin:</span> Log into the Digital Ocean web console
                    
                    <div className='hp8em' />
                    
                    <Tooltip title="Digital Ocean Home Page Web Console">
                        <Button type="button"
                            className="whiteOnWhiteBoxShadow"
                            style={{ width: config.detectMobile.isMobile() ? "60vw" : "40vw", fontWeight: "800" }}
                            variant="primary"
                            onClick={() => { window.open('https://www.digitalocean.com', '_blank'); }}
                        >
                            DIGITAL OCEAN HOME PAGE
                        </Button>
                    </Tooltip>

                    <div className='h1em' />

                    Choose droplets from the <span className="boldLightBlue">left</span> side menu.
                    <div className='hp5em' />

                    <img src={DropletTS01} alt="droplets"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Select your Droplet
                    <div className='hp5em' />

                    <img src={DropletTS02} alt="select droplet"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Hard power off your droplet by clicking on
                    the <span className="boldLightBlue">ON/OFF</span> switch. Patiently, Wait
                    for the green <span className="boldLightGreen">ON</span> to change to a
                    gray <span className="boldGray">OFF</span>.
                    <div className='hp5em' />

                    <img src={DropletTS03} alt="on off"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Turn off</span> (if presented with this warning).
                    <div className='hp5em' />

                    <img src={DropletTS07} alt="off warning"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Recovery</span>.
                    <div className='hp5em' />

                    <img src={DropletTS04} alt="recovery"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Boot from Recovery ISO</span>
                    {' '}option.
                    <div className='hp5em' />

                    <img src={DropletTS05} alt="select iso"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Turn our Droplet back <span className="boldLightBlue">ON</span>
                    {' '}option.
                    <div className='hp5em' />

                    <img src={DropletTS06} alt="select iso"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Click on <span className="boldLightBlue">Recovery Console</span>
                    {' '}which will open up a new browser pop-up window.
                    <div className='hp5em' />

                    <img src={DropletTS08} alt="select iso"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Click in the new window and choose <span className="boldLightBlue">1</span>
                    {' '}by typing in the number <span className="boldLightBlue">1</span> with your
                    keyboard, and hit enter <MdKeyboardReturn size={25} />.
                    {' '}This will mount our corrupted original Node hard disk to the recovery
                    image.
                    <div className='hp5em' />

                    <img src={DropletTS09} alt="mount"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Should change to...
                    <div className='hp5em' />
                    <img src={DropletTS10} alt="mount 2"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    We will now attempt to <span className="boldLightBlue">chroot</span>
                    {' '}to our system and access our hard drive.  The purpose of this exercise is to make
                    sure we have the files we need in order to continue to the next steps.
                    We are making sure your file are in existence. Choose <span className="boldLightBlue">5</span>
                    {' '}on your keyboard, and hit enter <MdKeyboardReturn size={25} />.
                    <div className='hp5em' />
                    <img src={DropletTS11} alt="chroot"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Should change to...
                    <div className='hp5em' />
                    <img src={DropletTS12} alt="recovery console"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    <span className="boldRed">Important</span>: Most of us should have
                    used the <span className="boldLightBlue">recommended</span> settings when
                    originally creating our Node.

                    <div className='hp8em' />

                    There was a section where you were asked
                    to create a <span className="boldLightBlue">node administrator</span> username.  The
                    recommendation was <span className="boldLightGreen">nodeadmin</span>.  If you
                    decided to use something other than nodeadmin, you will replace nodeadmin with
                    this username you created. Enter in the following command, and hit
                    enter <MdKeyboardReturn size={25} />.

                    <CodeSnippet snipFontSize=".6em" code="cd /home/nodeadmin/tessellation" />
                    If the command causes your system to create a new line with a <span className="boldLightBlue">#</span>
                    {' '}and no error message or other messages, it was successful.  If not, make sure your username is
                    correct.

                    <div className='hp5em' />

                    <img src={DropletTS13} alt="recovery console 2"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />
                    Enter in the following command, and hit
                    enter <MdKeyboardReturn size={25} />. {' '}
                    <div className="font-p6em colorRed">( the lower-case letter L not the number 1 )</div>

                    <CodeSnippet snipFontSize=".6em" code="ls -l" />

                    <div className='hp5em' />
                    <img src={DropletTS14} alt="recovery console 3"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    We should see our <span className="boldLightBlue">p12</span> file here.  The example has the name of the
                    {' '}<span className="boldLightBlue">p12</span> blurred out.
                    <div className='hp5em' />
                    <img src={DropletTS15} alt="p12 found"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    If you do not see your <span className="boldLightBlue">p12</span> file listed here
                    then you probably will not be able to continue with the backup process and should
                    skip down to the restore node
                    <div>
                        <HashLink smooth to="#step4_image">
                            <NextButtonCloudService nextName="RESTORE NODE (STEP 4)" nextLink="." skipNext={true} />

                        </HashLink>
                    </div>
                    section and continue
                    without a backup of your <span className="boldLightBlue">p12</span>.  This is not a huge problem
                    for <span className="boldLightBlue">TestNet</span>, but for <span className="boldLightBlue">MainNet</span>,
                    this would be <span className="boldRed">bad</span>.

                    <div className='h1em' />

                    For the rest of us, let keep going...

                    <div className='h1em' />
                    Enter in the following command, and hit
                    enter <MdKeyboardReturn size={25} />. {' '}

                    <CodeSnippet snipFontSize=".6em" code="cd /usr/local/bin" />

                    If the command causes your system to create a new line with a <span className="boldLightBlue">#</span>
                    {' '}and no error message or other messages, it was successful.  If not, make sure the command you
                    entered was correct.

                    <div className='hp5em' />
                    <img src={DropletTS16} alt="recovery console 4"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />


                    <div className='h1em' />
                    Enter in the following command, and hit
                    enter <MdKeyboardReturn size={25} />. {' '}
                    <div className="font-p6em colorRed">( the lower-case letter L not the number 1 )</div>

                    <CodeSnippet snipFontSize=".6em" code="ls -l" />

                    <div className='hp5em' />
                    <img src={DropletTS18} alt="recovery console 5"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Look for the <span className="boldLightBlue">cn-node</span> file.
                    <div className='hp5em' />
                    <img src={DropletTS17} alt="recovery console 6"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    If we see it, we are in great shape! Let's continue.

                    <div className='h1em' />

                    You can{' '}<span className="boldLightBlue">exit</span> out of your recovery console
                    by <span className="boldLightBlue">X</span>ing out the browser window with the
                    black background console window.  If you do <span className="boldRed">not</span>
                    {' '}see the <span className="boldLightBlue">cn-node</span> file, you can still continue,
                    but bear-in-mind, you will not restore this file later.

                    <div className="h1em" />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step3_image"><Badge bg="success">STEP THREE</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Backup our critical files</div>
                    <div className='hp8em' />

                    Choose your Operating System to continue...

                    <div className='h1em' />

                    <div className="whiteOnWhiteBoxShadow" style={{ width: "73vw", padding: "10px" }}>
                        <Tabs defaultActiveKey="win" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="win" title="Windows">
                                <DigitalOceanImageWin type="backup" />
                            </Tab>
                            <Tab eventKey="mac" title="Apple/Linux">
                                <DigitalOceanImageMac type="backup" />
                            </Tab>
                        </Tabs>
                    </div>

                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step4_image"><Badge bg="success">STEP FOUR</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Rebuild/Recover our Droplet.</div>
                    <div className='hp8em' />

                    Our <span className="boldLightBlue">p12</span> and our <span className="boldLightBlue">cn-node</span> file
                    should be backed up.

                    <div className='hp3em' />

                    <div className="boldRed" style={{fontSize: "2em"}}>or</div>

                    <div className='hp3em' />

                    We skipped the backup and are ready to just continue
                    recovering <span className="boldLightBlue">most</span> of our Node. 
                    
                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    We can get back on the network and
                    continue!

                    <div className='h1em' />

                    Return the the <span className="boldLightBlue">Digital Ocean Web Console</span>.

                    <div className='h1em' />

                    We should still be in the <span className="boldLightBlue">Recovery</span> section.

                    <img src={DropletTS04} alt="recovery"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Hard power off your droplet by clicking on
                    the <span className="boldLightBlue">ON/OFF</span> switch. Patiently, Wait
                    for the green <span className="boldLightGreen">ON</span> to change to a
                    gray <span className="boldGray">OFF</span>.
                    <div className='hp5em' />

                    <img src={DropletTS03} alt="on off"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Turn off</span> (if presented with this warning).
                    <div className='hp5em' />

                    <img src={DropletTS07} alt="off warning"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Boot from Hard Drive</span>
                    {' '}option.
                    <div className='hp5em' />

                    <img src={DropletTS24} alt="select hard"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    Choose <span className="boldLightBlue">Destroy</span>.
                    <div className='hp5em' />

                    <img src={DropletTS19} alt="destroy"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />
                    <div className='h1em' />

                    Scroll down to the <span className="boldLightBlue">Rebuild Droplet</span> section.
                    <div className='hp5em' />

                    <img src={DropletTS20} alt="rebuild 1"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />
                    <div className='h1em' />

                    Select Ubuntu <span className="boldLightBlue">20.04</span> (LTS) x64.
                    <div className='hp5em' />

                    <img src={DropletTS21} alt="rebuild 2"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />
                    <div className='h1em' />

                    Click on <span className="boldLightBlue">Rebuild</span>.
                    <div className='hp5em' />

                    <img src={DropletTS22} alt="rebuild 3"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />
                    <div className='h1em' />

                    Click on <span className="boldLightBlue">Confirm</span>.  This is going to
                    rebuild our Node using all the settings we previously chose when we <span className="boldLightBlue">originally</span>{' '}
                    created this Droplet (before it got corrupted).  
                    
                    <div className='h1em' />

                    This will allow us to skip a bunch of Digital Ocean
                    configuration options that normally we would have to go through.
                    {' '}<span className="boldRed">WARNING</span> this will <span className="boldRed">erase</span> your original
                    hard drive contents, so you will have a <span className="boldLightBlue">clean</span>
                    {' '}<span className="boldLightGreen">new</span> image with no data.

                    <div className='h1em' />

                    Click <span className="boldLightBlue">confirm</span>

                    <div className='hp5em' />

                    <img src={DropletTS23} alt="rebuild 5"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />
                    <div className='h1em' />

                    Allow this process to complete before continuing.

                    <div className='h1em' />

                    Turn our Droplet back <span className="boldLightBlue">ON</span>
                    {' '}option. (It may turn on automatically)
                    <div className='hp5em' />

                    <img src={DropletTS06} alt="turn on"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />
                    <div className='hp5em' />

                    <img src={DropletTS03} alt="turn on 2"
                        className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                    <div className='h1em' />

                    You can now log out of <span className="boldLightBlue">Digital Ocean's Web Console</span>.
                    You have now <span className="boldLightGreen">recovered</span> your <span className="boldLightBlue">Node</span>,
                    excellent work!

                    <div className='h1em' />


                    As you are <span className="boldLightBlue">used</span> to doing before your Node became <span className="boldLightBlue">corrupted</span>...
                    
                    <div className='hp8em' />

                    Macintosh or Linux users, we will change
                    the username in our <span className="boldLightBlue">ssh</span> command to <span className="boldLightBlue">root</span>.

                    <div className='hp8em' />

                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <span className="boldRed13f">EXPAND FOR DETAILS ACCESSING YOUR NODE FROM A MACINTOSH OR LINUX SYSTEM</span>
                            </Accordion.Header>
                            <Accordion.Body style={{ backgroundColor: "#131327" }}>

                                Since you have been accessing your <span className="boldLightBlue">Node</span> for some time now, the procedure to
                                enter into the <span className="boldLightBlue">sftp</span> console, is the <span className="boldLightGreen">EXACT</span>
                                {' '}same as you have been doing in order to access your Node (which was by opening an <span className="boldLightBlue">SSH</span>
                                {' '}session to your remote Droplet).  The only difference is the word <span className="boldLightBlue">sftp</span> instead
                                of <span className="boldLightBlue">ssh</span>.

                                <div className='hp5em' />

                                To access our <span className="boldLightBlue">Node</span> as you always have done in the past (ssh key and ip address are made up here)
                                <CodeSnippet snipFontSize=".6em" code="ssh -i ~/.ssh/my-ssh-rsa-private-key root@111.111.111.111" />
                                This will change to
                                <CodeSnippet snipFontSize=".6em" code="sftp -i ~/.ssh/my-ssh-rsa-private-key root@111.111.111.111" />

                                <div className='hp5em' />

                                If you do <span className="boldRed">not</span> have your <span className="boldLightBlue">ssh</span> private key in your <span className="boldLightBlue">.ssh</span> directory it is probably in
                                the root of your <span className="boldLightBlue">home</span> directory.

                                To access our Node as you always have done in the past (ssh key and ip address are made up here)
                                <CodeSnippet snipFontSize=".6em" code="ssh -i ~/my-ssh-rsa-private-key root@111.111.111.111" />
                                This will change to
                                <CodeSnippet snipFontSize=".6em" code="sftp -i ~/my-ssh-rsa-private-key root@111.111.111.111" />
                                <div className='hp8em' />

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className='h1em' />

                    Windows users can attempt to access your Node via <span className="boldLightBlue">PuTTy</span>; however,
                    remember we <span className="boldRed">no longer</span> have our <span className="boldLightBlue">nodeadmin</span>
                    {' '} account, it was <span className="boldRed">erased</span>.  So we need to change our user in <span className="boldLightBlue">PuTTy</span> back to
                    {' '}<span className="boldLightBlue">root</span>.
                    
                    <div className="h1em" />

                    Windows User (and Macintosh if the above didn't help) use the link <ImPointDown size={25} /> to
                    get a refresher on how to do this through <span className="boldLightBlue">PuTTy</span>.  Also you can use the YouTube videos.

                    <div className="hp5em" />

                    <NextButtonCloudService nextName="Access our cloud instance" nextLink="/access-vm" skipNext={true} />

                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step5_image"><Badge bg="success">STEP FIVE</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Install nodectl and Tessellation</div>

                    <div className='h1em' />

                    In the next step in our recovery process, we will once
                    again leave this section, follow the steps to download <span className="boldLightBlue">nodectl</span>.  Once you
                    done, return back here.  (<span className="colorLightBlue" style={{fontSize: ".8em"}}>You can use the quick links at the top of this page to return to Step #5.</span>)

                    <div className="hp5em" />

                    Follow the link via the button <ImPointDown size={25} /> below.

                    <div className="hp5em" />

                    <NextButtonCloudService nextName="nodectl installation" nextLink="/nodectl/install" skipNext={true} />
                    <div className='h1em' />

                    We will now use <span className="boldLightBlue">nodectl</span> to install and configure your
                    Node with <span className="boldLightBlue">tessellation</span>. 
                    
                    <div className='h1em' />

                    <span className="boldOrange">Important</span>
                    {' '}: What we are doing is a brand new install of everything as if it is a new Droplet that we
                    are turning into a full <span className="boldLightBlue">Validator</span> Node, for the first time.

                    <div className='h1em' />

                    Then we are going to copy our <span className="boldLightBlue">backup</span> files back over to
                    the <span className="boldLightBlue">Node</span>
                    {' '}over-writing the <span className="boldLightBlue">new</span> with your <span className="boldLightBlue">original</span>
                    {' '} existing configuration.

                    <div className="h1em" />
                    Although we are over-writing our <span className="boldLightBlue">new</span> if our <span className="boldLightBlue">original</span>
                    {' '}files in later steps, one suggestion is to name your <span className="boldLightBlue">p12</span> file the <span className="boldLightGreen">same name</span> as you
                    originally named it during the original installation.

                    <div className="h1em" />

                    It is <span className="boldRed">important</span> that if you are restoring your <span className="boldLightBlue">p12</span>{' '} 
                    from the{' '}<span className="boldLightGreen">original</span>{' '}Node, you remember the <span className="boldLightGreen">original</span>{' '}
                    file name before you continue.  

                    <div className="h1em" />

                    You backed up this file in the previous steps, so that is the name you will
                    give your file during the installation. Reminder of that step is shown below <ImPointDown size={25} />

                    <div className="h1em" />

                    <img src={DropletTS15} alt="p12 found"
                        className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    <div className="h1em" />

                    This is <span className="boldRed">not</span> {' '} <span className="boldLightBlue">absolutely necessary</span>
                    {' '}however, it allows you to simply write over the <span className="boldLightBlue">new</span> with
                    the <span className="boldLightBlue">original</span>
                    {' '}instead of having two files; thus, avoiding the need to clean up after 
                    yourself later.

                    <div className="h1em" />

                    Follow the link via the 
                    button <ImPointDown size={25} /> below for a refresher on how to use{' '}
                    <span className="boldLightBlue">nodectl</span>.
                    <div className='h1em' />

                    <NextButtonCloudService nextName="nodectl usage" nextLink="/nodectl/usage" skipNext={true} />

                    <div className="h1em" />

                    Once you done, return back here!  

                    <div className='h1em' />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step6_image"><Badge bg="success">STEP SIX</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Restore our files.</div>
                    <div className='hp8em' />

                    If you did not recovery your files from your corrupted Droplet, you do not need
                    to continue, you are <span className="boldLightGreen">DONE</span>!

                    <div className='h1em' />
                    
                    Those recovering files, let's continue...

                    <div className='h1em' />


                    We should now have:
                    <div className="font-p6em">
                        <ul>
                            <li>Our files <span className="boldLightBlue">backed up</span></li>
                            <li>Our Node <span className="boldLightBlue">rebuilt</span></li>
                            <li><span className="boldLightBlue">nodectl</span> installed</li>
                            <li><span className="boldLightBlue">tessellation</span> installed on our system
                                via <span className="boldLightBlue">nodectl</span></li>
                            <li>Named our <span className="boldLightBlue">p12</span> file the same
                                name as when we originally built our <span className="boldLightBlue">Node</span>.</li>
                        </ul>
                    </div>

                    <div className="h1em" />

                    Let's <span className="boldLightBlue">restore</span> our file! Choose your Operating System to continue...

                    <div className='h1em' />

                    <div className="whiteOnWhiteBoxShadow" style={{ width: "73vw", padding: "10px" }}>
                        <Tabs defaultActiveKey="win" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="win" title="Windows">
                                <DigitalOceanImageWin type="restore" />
                            </Tab>
                            <Tab eventKey="mac" title="Apple/Linux">
                                <DigitalOceanImageMac type="restore" />
                            </Tab>
                        </Tabs>
                    </div>

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div id="step7_image"><Badge bg="success">STEP SEVEN</Badge></div>
                    <div className="colorPurple" style={{ fontSize: ".9em" }}>Final Touches and Restart our Node</div>
                    <div className='hp8em' />

                    Everything should be completed, all our files in place and restored!

                    <div className="h1em" />

                    Let's restart our Node.

                    <div className="h1em" />

                    Open up <span className="boldLightBlue">PuTTy</span> or your <span className="boldLightBlue">Apple/Linux</span> Terminal window, and <span className="boldLightBlue">SSH</span> into your
                    Node, as normal procedure would dictate.  You will access your <span className="boldLightBlue">Node</span> as <span className="boldLightBlue">nodeadmin</span> 
                    {' '}<span className="boldRed">not</span> root.

                    <div className="h1em" />

                    If you were <span className="boldRed">unable</span> to migrate your <span className="boldLightBlue">cn-node</span> file to its proper
                    location because you were not the <span className="boldLightBlue">root</span> user, you should have saved it to your <span className="boldLightBlue">nodeadmin</span> home
                    directory.  (If not, you can skip this step.)

                    <CodeSnippet
                        snipFontSize=".5em"
                        code="sudo mv /home/nodeadmin/cn-node /usr/local/binNEWLINE" />

                    <div className="h1em" />

                    We need to change the file permissions on our <span className="boldLightBlue">cn-node</span> file, because
                    it needs to be executable.

                    <CodeSnippet
                        snipFontSize=".5em"
                        code="sudo chmod +x /usr/local/bin/cn-node" />

                    <div className="h1em" />

                    We need to change the file permissions on our <span className="boldLightBlue">p12</span> file, because
                    it is a private key file.

                    <CodeSnippet snipFontSize=".5em" code="sudo chmod 600 /home/nodeadmin/tessellation/myp12file.p12" />

                    <span className="font-p5em boldRed">Change the name of your p12 file to the your p12 file name.</span>
                    <div className="h1em" />

                    Let's go ahead and do a slow restart to finish off the process.
                    <CodeSnippet snipFontSize=".5em" code="sudo nodectl slow-restart" />

                    <div className="h1em" />

                    In <span className="boldLightBlue">10 minutes</span> you should be back on the network!

                    <div className="h1em" />

                    <span className="boldLightGreen">Thank you for your patience during this process!</span>

                </div>
            </div>
            <TopOfPageLink hash="#doNodeTop" />
        </>

    );
}

export default DigitalOceanTS;








