import React from 'react'
import { Table, Badge } from "react-bootstrap";

import { useConfig } from "../utils/Configuration";

function NodeAssumptions() {

    const config = useConfig()
    const configurationState = config.providerInfo

    return (
        <>
            <div className="font-p7em colorWhite">
                These are assumptions made during the step-by-step below, you will need to
                {' '}<Badge bg="danger">change</Badge>{' '}
                these to match your configuration, if you decide to change anything we suggest in this documentation.
            </div>

            <div className="hp5em" />

            <div className={config.detectMobile.isMobile() ? "font-p3em" : "font-p4em"} />
            <div style={{ backgroundColor: "rgb(215, 215, 231)" }}>
                <Table striped bordered hover style={{verticalAlign: "middle"}}>
                    <thead className="colorBlack">
                        <tr>
                            <th>Variable</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody className="colorBlack">
                        <tr>
                            <td width="25%" style={{verticalAlign: "middle"}}>
                                <span className="font-p7em boldBlue">Cloud {configurationState.instance} hostname</span>
                            </td>
                            <td >
                                <div className="font-p7em">
                                    node-garage {configurationState.provider === "aws"
                                        ?
                                        <div className="font-p6em">
                                            <div className="hp5em" />
                                            <Badge>Note:</Badge>{' '}AWS may use @you-nodes-assigned-ip-address.
                                            <div className="hp5em" />
                                        </div>
                                        : ""}
                                    {' '}Your {configurationState.instance} will <span className="boldRed">not</span>{' '}
                                    have the same hostname.  Substitute node-garage with whatever your {configurationState.instance}
                                    {' '} has been called.
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">
                                <span className="font-p7em boldBlue">User we will work with or add</span>
                            </td>
                            <td>
                                <div className="font-p7em">
                                    nodeadmin
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">
                                <div className="font-p7em boldBlue">[...]</div>
                            </td>
                            <td>
                                <span className="font-p7em">
                                    When you see this in our examples, it will mean that there may be extra output from a command issued.
                                    The output is <span className="boldRed">not</span> important for our purposes, so it is redacted.  The symbol will be
                                    shown above the code that is important or below the code that is important.
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td><div className="font-p7em boldBlue">Results Window</div></td>
                            <td>
                                <div className="backgroundColorGray font-p7em">
                                    The windows background color of a dark gray is information the will result
                                    from a command and may not need you to copy and paste.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default NodeAssumptions