var CryptoJS = require("crypto-js");

function genPassword(password) {

  var salt = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Hex)

  var genHash = CryptoJS.PBKDF2(password, salt, {
    keySize: 16,
    iterations: 1000
  }).toString(CryptoJS.enc.Hex);

  return {
    salt: salt,
    hash: genHash
  };
}

function validPassword(password, hash, salt) {
  // hash
  // c549b9e86976c89d57c0443d748eaef10948c4b1fd9fee2f673354464cd53c569a2c520bea6b9ee51f460b46c2787ffab5c2e5b72af5a9d1f162a9a461604e00
  // 1c11482256203749c02e5803619cd44afd5d8a821bac1bd41c65ed2922b83762317497e5b961356277fedb1134165f17d0d7c809aecaf38ad4b50c20e0750679
  // salt
  // 2c9e07b4972866eef0de7ec118ba42c558918fa4eaf6e6551e15f7a7949f0729
  // 328f54e8c93472d705450d20567fbbd807aabd95ad42e7a6e29fd2fa1cb05970

  var hashVerify = CryptoJS.PBKDF2(password, salt, {
    keySize: 16,
    iterations: 1000
  }).toString(CryptoJS.enc.Hex);
  return hash === hashVerify;
}

module.exports.validPassword = validPassword;
module.exports.genPassword = genPassword;