import React from 'react'

function CheckList({ children },) {
    return (
        <>
            <div className="centeredFlexBoxRows font-p4em colorGray">
                {children}
            </div>
        </>
    )
}

export default CheckList