import '../css/App.css';
import '../css/Main.css';

import { useAuth } from '../utils/Auth';
import { useConfig } from '../utils/Configuration';

import UserDetails from "./UserDetails"
import CNlogo from "../images/CNLogoTrans.png"
import SpinningLogo from '../videos/SpinningLogo.mp4';

export default function FrontPage() {
    const auth = useAuth()
    const config = useConfig()

    var distFromTop = ""
    var videoHeight = ""

    if (config.detectMobile.isMobile()) {
        distFromTop = "fontVmMinus1 windowLocation20p-neg"
        videoHeight = "55%"
    } else {
        distFromTop = "fontVmMinus1"
        videoHeight = "65%"
    }
    return (
        <>

            {!auth.isValidated &&
                <div className={distFromTop} >


                    <video
                        autoPlay
                        muted
                        style={{
                            position: "fixed",
                            width: "150%",
                            left: "-25vw",
                            height: videoHeight,
                            top: 100,
                            opacity: .7,
                            transition: "opacity, 1s ease-in-out"
                        }}
                    >
                        <source src={SpinningLogo} type="video/mp4" />
                    </video>


                </div>
            }

            <div className='h1rem' />

            {auth.isValidated ?
                <div className="bringToFront">
                    <UserDetails />
                </div>
                : null
            }

            <div className="bottomFullWidth bottom60px pointerCursor">
                <span className="fontVmMinus1p4 constellationBlue">
                    Powered by&nbsp;&nbsp;
                </span>
                <span className="constellationBlue" onClick=
                    {
                        () => window.open('https://constellationnetwork.io', '_blank')
                    }>
                    Constellation Network
                    <img className="CNLogo" src={CNlogo} alt="Constellation Network Logo" />
                </span>
            </div>
        </>
    )
}
