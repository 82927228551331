import { useEffect, useState } from 'react';
import { useConfig } from "../utils/Configuration";
import { useAuth } from "../utils/Auth";
import { useNavigate } from 'react-router-dom'

import Switch from '@mui/material/Switch';
import TopMenu from "./TopMenu";
import LogoWithTitle from "./LogoWithTitle";
import { ReactComponent as NGLogo } from '../images/NgLogoSvg.svg';

function Disclaimer() {

    const config = useConfig()
    const auth = useAuth()

    const [disclaimerValue, setDisclaimerValue] = useState(false);
    const [pageTimeout, setPageTimeout] = useState(500)

    const navigate = useNavigate()

    const handleDisclaimer = (value) => {
        auth.updateDatabase({
            table: 5598,
            key: "disclaimer",
            data: 1,
            crud: "update"
        })
        if (value) {
            // allow short pause to see switch change
            auth.setLoggedIn({
                ...auth.loggedIn,
                disclaimerAgreed: value
            })
            setTimeout(() => {
                navigate("/welcome")
            }, 500);
        }
    }

    useEffect(() => {
        auth.loggedIn.disclaimerAgreed ? setPageTimeout(0) : setPageTimeout(500)
        setDisclaimerValue(auth.loggedIn.disclaimerAgreed)
    }, [])

    useEffect(() => {
        if (!config.disclaimer.manualReview) {
            if (disclaimerValue) {
                setTimeout(() => { navigate("/welcome") }, pageTimeout)
            } else {
                return
            }
        }
    }, [disclaimerValue])

    return (
        <>
            <TopMenu />
            <div className="navigationFlex">
                <div className="navigationFullWidth">
                    <NGLogo
                        className={config.detectMobile.isMobile() ?
                            "backgroundLogoRightPanelMobile" :
                            "backgroundLogoRightPanel"} />
                    <section id="mainSection" className="fade-in">
                        <div className="informationWhiteBox w-65">
                            <LogoWithTitle
                                text="DISCLAIMER"
                                logoHeight="5rem"
                                fontClass="font2em" />
                            <div className="h1em" />
                            <div>
                                NOTICE AND DISCLAIMER PLEASE READ THE ENTIRETY OF THIS "NOTICE AND DISCLAIMER" SECTION CAREFULLY. NOTHING HEREIN
                                CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR
                                OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. NEITHER NODE GARAGE,
                                LIMITED LIABILITY CORPORATION (THE COMPANY), ANY OF THE PROJECT TEAM MEMBERS (THE NODE GARAGE FOUNDING TEAM)
                                WHO ARE WORKING TOWARDS CREATING A STATE CHANNEL BUSINESS ON THE CONSTELLATION NETWORK OR HGTP NETWORK (AS MENTIONED HEREIN)
                                OR PROJECT TO DEVELOP ON THE CONSTELLATION NETWORK OR HGTP NETWORK IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF
                                $DAG OR $NGT TOKENS (THE DISTRIBUTOR), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT
                                DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THE TOKEN DOCUMENTATION OR ANY OTHER
                                WEBSITES OR MATERIALS PUBLISHED BY THE COMPANY.
                            </div>
                            <div className="h1em" />
                            <div className="colorLightBlue font-p9em">
                                In order to provide the best experience on our website, we use cookies to help make sure that the website
                                functions normally.  We may analyze some minor information about your site visits; however, we do not share any
                                information with third parties (other than Constellation Network and Constellation Network Labs).
                            </div>
                            <div className="h2em" />
                            <div style={{ textAlign: 'center' }}>
                                {!config.disclaimer.manualReview &&
                                    <div>
                                        <b>
                                            I agree that I read the disclaimer, understand and about cookie usage
                                        </b>
                                        <div className="hp5em" />
                                    </div>
                                }

                                <span className="switchOnBlackBg">
                                    <Switch color="error" onChange={() => handleDisclaimer(true)} />
                                </span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </>

    );
}

export default Disclaimer;








