import MobileWarning from "../../components/MobileWarning";

import { useConfig } from "../../utils/Configuration";
import CheckList from "../../components/CheckList";
import CheckListRow from "../../components/CheckListRow";

import NodeAssumptions from "../../components/NodeAssumptions";
import CodeSnippet from '../../components/CodeSnippet';
import PageTitle from "../../components/PageTitle";
import UpdateUpgrade from "../../components/UpdateUpgrade";
import TopOfPageLink from "../../components/TopOfPageLink";
import XxsBut from "../../components/XxsBut";
import InstructionWithPic from "../../components/InstructionWithPic";

import { Button, Badge, Accordion, Table } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

import { ImPointDown, ImPointUp } from "react-icons/im";
import { TiArrowRightOutline } from "react-icons/ti";
//import NextButtonCloudService from "../../components/NextButtonCloudService";

import Rewards01 from "../../images/Rewards01.png";
import Rewards02 from "../../images/Rewards02.png";
import Rewards03 from "../../images/Rewards03.png";
import Rewards04 from "../../images/Rewards04.png";
import Rewards05 from "../../images/Rewards05.png";
import Rewards06 from "../../images/Rewards06.png";
import Rewards07 from "../../images/Rewards07.png";
import Rewards08 from "../../images/Rewards08.png";
import Rewards09 from "../../images/Rewards09.png";
import Rewards10 from "../../images/Rewards10.png";

function Rewards() {

    const config = useConfig()
    const configurationState = config.providerInfo

    const instruction01 = <div>
        You may have noticed when you first logged in, that you were directed to a home page on the site that had some
        minor details about your account.  At the bottom of the details box (<span className="colorLightBlue">if you did not have your
            {' '}<span className="boldWhite">Node's id</span> entered</span>) it will show you an add <span className="boldLightBlue">button</span>.
        The button that will direct you to a form that will allow you to add your Node id to your account.
    </div>

    const instruction02 = <div>
        <span className="boldLightBlue">Alternatively</span>, if you skipped this page and already entered the main site, you can choose the option from
        the side menu.  <span className="boldLightBlue">NOTE</span> you can also <span className="boldLightBlue">update</span> your Node's ID in this
        fashion as well.
    </div>

    const instruction03 = <div>
        <Badge bg="danger">IMPORTANT</Badge> We want to remove <span className="boldRed">all</span> information that is in the entry
        box prior to pasting in your <span className="boldLightBlue">Node's id</span>.  From the previous step, paste your
        {' '}<span className="boldLightBlue">Node's id</span> into the provided <span className="colorBlue">blue</span> box.
    </div>

    const instruction04 = <div>
        If you enter in an {' '}
        <span className="boldLightBlue">empty</span>, {' '}
        <span className="boldLightBlue">invalid Node id</span>, or the {' '}
        <span className="boldLightBlue">same Node id</span>, {' '}
        as already entered into the form, you will receive
        and <span className="boldRed">error</span> message.  Review your entry, and try again.
    </div>

    const instruction05 = <div>
        We now need to obtain a <span className="boldLightBlue">token</span> (digital certificate of authenticity) from
        {' '}<span className="boldLightBlue">Node Garage</span> for our <span className="boldLightBlue">Node ID</span>
    </div>

    const instruction06 = <div>
        Our request form will show up our <span className="boldLightBlue">Node id</span> pre-populated for us.
    </div>

    const instruction07 = <div>
        After a quick verification that our <span className="boldLightBlue">Node id</span> is correct. Click
        on the <Badge>CREATE DIGITAL TOKEN</Badge> button.
    </div>

    const instruction08 = <div>
        The <span className="colorGreen">green</span> box will populate with our
        {' '}<span className="boldLightBlue">digitally signed certificate</span>.
    </div>

    const instruction09 = <div>
        <Badge bg="warning">Optional</Badge>
        {' '}Let's verify <span className="boldLightBlue">digitally signed certificate</span>.
    </div>

    const instruction10 = <div>
        We should see a <span className="colorGreen">green</span> verified message.  If you do
        not and received a <span className="colorRed">failed</span>, verify your Node ID and
        try again.  If you continue to have issues, contact a Team Lead or Admin on the
        {' '}<span className="boldLightBlue">new-node-ops</span> Discord channel.
    </div>

    return (
        <>
            <div id="rewardsTop">
                <PageTitle title="TESTNET REWARDS INSTRUCTIONS" type={1} />

                <div className='h1em' />

                <div className="textAlignLeftPad w-65">

                    <div className="font-p6em colorLightGreen textAlignCenter">
                        <u>Should Be Completed Already</u>
                    </div>

                    <div className="hp2em" />


                    <CheckList>
                        <CheckListRow listItem="Cloud Provider Signed Up" />
                        <CheckListRow listItem={"Created " + configurationState.instance + " on your Cloud Provider"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem={configurationState.instance + " is running"} />
                        <CheckListRow listItem={"You are able to successfully access your Node as your non-root user"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="p12 key file created" />
                        <CheckListRow listItem="Node package dependencies installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="node bash script created" />
                        <CheckListRow listItem="Tessellation package binaries installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="You are no longer using your root user" />
                        <CheckListRow listItem="Tessellation service created, enabled and started" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="Your Node is successfully joined to the TestNet" />
                    </CheckList>

                    <div className='h2em' />

                    {config.detectMobile.isMobile() ?
                        <div>
                            <MobileWarning />
                            <div className="h2em" />
                        </div>
                        : " "
                    }

                    Setting up our {' '}
                    <span className="boldLightBlue">
                        Constellation TestNet Validator Node
                    </span>
                    {' '}to earn rewards is a relatively simple process!

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Badge bg="success">HOW DOES THIS WORK?</Badge>{' '}
                                    <div className="hp4em" />
                                    <TiArrowRightOutline size={35} />
                                    <div><span className="boldBlack">Discussion</span>: Expand the section if you are interested in learning
                                        about the <span className="boldBlue">types</span> of Nodes, differences,
                                        and how the reward earnings "work".</div>
                                </Accordion.Header>
                                <Accordion.Body style={{ fontSize: ".6em" }}>
                                    <Table striped bordered hover className={config.detectMobile.isMobile() && "font-p5em"}>
                                        <thead>
                                            <tr>
                                                <th>NODE TYPE</th>
                                                <th>STAKING REQUIRED</th>
                                                <th>EXPLANATION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: "black" }} colSpan="4"></td>
                                            </tr>
                                            <tr>
                                                <td><span className="colorBlue">Soft Nodes</span></td>
                                                <td className={config.detectMobile.isMobile() ? "font-p6em" : "font-p8em"}>
                                                    YES
                                                </td>
                                                <td className={config.detectMobile.isMobile() ? "font-p6em" : "font-p8em"}>
                                                    Soft Nodes are fully managed virtual Nodes that are running in the background.  These
                                                    Nodes require only that you have a{' '}
                                                    <XxsBut link="https://lattice.exchange/"
                                                        bgColor="#ffcc00" color="black"
                                                        hoverBgColor="#6e674e" hoverColor="black"
                                                        text="Lattice" ext={true}
                                                    />{' '} account setup, and you have staked your 250,000 $DAG to create the
                                                    Soft Node.  Dependent on how much $DAG you own dictates how many Soft Nodes
                                                    you can run.  Rewards are distributed accordingly.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="colorBlue">Foundational Validator Nodes</span></td>
                                                <td className={config.detectMobile.isMobile() ? "font-p6em" : "font-p8em"}>YES</td>
                                                <td className={config.detectMobile.isMobile() ? "font-p6em" : "font-p8em"}>
                                                    Foundational Nodes are the <span style={{ fontStyle: "oblique" }}>creme de la creme</span>
                                                    {' '}of the Constellation Network Node ecosystem.  These are Nodes that have been created,
                                                    tested, and deployed to Constellation Network's HGTP MainNet 1.0.   Foundational Nodes receive rewards
                                                    via a special wallet private key object (p12) file.  If the Foundational Node is on the
                                                    network, staking properly, and participating, it will be eligible to receive rewards directly
                                                    to the Node itself.  This will be the defacto-standard for the future of Validator Nodes.
                                                    In order to own a Validator Node; owners had to go through an application process, be picked to
                                                    participate, and they went through the full journey to help Constellation turn up MainNet 1.0.
                                                    There are{' '}
                                                    <Badge bg="danger">NO</Badge>{' '}
                                                    more Foundational Node that will be created.  This program is currently closed.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="colorBlue">TestNet Validator Nodes</span></td>
                                                <td>NO</td>
                                                <td className={config.detectMobile.isMobile() ? "font-p6em" : "font-p8em"}>
                                                    If you are on this (Node Garage) website... You most probably own a{' '}
                                                    <span className="boldBlack">
                                                        TestNet Validator Node
                                                    </span>
                                                    . These Nodes are the new and improved version of a Foundational Validator Node.  They
                                                    operate in the same manner, but with different "guts" under-the-hood which have been improved
                                                    over MainNet 1.0, and are helping us prepare for MainNet 2.0.  In order to obtain rewards
                                                    on a TestNet Validator Node, you do <Badge bg="success">NOT</Badge> have to own any $DAG,
                                                    and you do not have to stake 250,000 $DAG!  You will be required to have your Node up and running,
                                                    be diligent and cooperative in the TestNet Discord channel, and act properly with etiquette and
                                                    respect for your fellow TestNet Node Operators.  If your Node is <Badge bg="danger">not</Badge>{' '}
                                                    online, you will not receive rewards.  If there is an action requested and you do not fullfil
                                                    that action, you will <Badge bg="danger">not</Badge> receive rewards.  Rewards are distributed via
                                                    {' '}
                                                    <XxsBut link="https://lattice.exchange/"
                                                        bgColor="#ffcc00" color="black"
                                                        hoverBgColor="#6e674e" hoverColor="black"
                                                        text="Lattice" ext={true}
                                                    />
                                                    {' '} in the same manner as Soft Node rewards. Please continue with
                                                    this <span style={{ fontStyle: "oblique" }}>how to</span> document to setup and learn how to
                                                    start receiving rewards. Constellation Network and Node Garage reserve the
                                                    right to revoke your participation at any time.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>

                    <div className="h2em" />

                    <PageTitle title="LETS BEGIN OUR SETUP!" type={2} />

                    You can either read through the document and then execute the instructions, or <span className="boldLightBlue">even better</span>
                    {' '}open up a secondary window or tab on your computer with another instance of the <span className="boldLightBlue">Node Garage's website</span> up
                    and running, side-by-side.

                    <div className="h2em" />

                    <NodeAssumptions />

                    <div className="h2em" />

                    From your local system, log into your cloud {configurationState.instance}'s terminal
                    as <span className="boldLightBlue">nodeadmin</span>.
                    <div className="h1em" />

                    <UpdateUpgrade snipFontSize=".6em" user="nodeadmin" />

                    <div className="h2em" />
                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    <div className="hp5em" />

                    <span className="boldRed">IMPORTANT</span>: You need to be running version {' '}
                    <span className="boldLightBlue">
                        {config.versioning.nodectlVersion}
                    </span> of {' '}
                    <span className="boldLightBlue">nodectl</span> before continuing.  Please click on the <span className="boldLightBlue">nodectl installation</span>
                    {' '} link on the left side before continuing and follow the instructions to upgrade.

                    <div className="h2em" />
                    We will need to obtain our <span className="boldLightBlue">node id</span> from our Node.
                    <CodeSnippet snipFontSize=".6em" code="sudo nodectl id" />

                    Output should look similar to this:
                    <CodeSnippet snipFontSize=".6em" bgColorClass="backgroundColorGray" code="
                    nodeadmin@node-garage:~# sudo nodectl idNEWLINE
                    IP ADDRESSNEWLINE
                    111.111.111.111NEWLINE
                    NODE IDNEWLINE
                    abc123abc123edf456abc123abc123edf456abc123abc123edf456abc123abc123edf456abc123abc123edf456abc123abc123edf456abc123abc123edf456abNEWLINE
                    " />
                    {' '}<Badge bg="danger">NOTE</Badge> The <span className="boldLightBlue">IP address</span> and
                    {' '}<span className="boldLightBlue">Node id</span> above <ImPointUp size={25} /> are <span className="boldRed">fake</span>.
                    <div className="h1em" />

                    <span className="boldLightBlue">Copy and paste</span> your Node's ID to your computer's clipboard.

                    <div className="h1em" />
                    This is <span className="boldLightBlue">all</span> we need from our Node. We can now resume our setup using the{' '}
                    <span className="boldLightBlue">Node Garage</span> website.

                    <div className="h2em" />
                    We can exit out of our Node.  This will cause the window to disappear on Windows or for us
                    to return to our terminal, on a Linux or Apple system (assuming you are using the terminal).
                    <CodeSnippet snipFontSize=".6em" code="exit" />
                    <div className="h1em" />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    <div className="h1em" />

                    <InstructionWithPic instruction={instruction01}
                        cardImageTitle="NODE ID ADDITION"
                        cardImageSubTitle="Click to goto the form to enter your Node ID"
                        cardImageLocation={Rewards01}
                        cardImageAltText={"Enter Node ID option 1"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction02}
                        cardImageTitle="FROM NAVIGATION"
                        cardImageSubTitle="Click to goto the form to enter your Node ID"
                        cardImageLocation={Rewards02}
                        cardImageAltText={"Enter Node ID option 2"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction03}
                        cardImageTitle="THE FORM"
                        cardImageSubTitle="Fill it out"
                        cardImageLocation={Rewards03}
                        cardImageAltText={"Enter Node ID form"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction04}
                        cardImageTitle="ADD OR UPDATE"
                        cardImageSubTitle="Click to submit"
                        cardImageLocation={Rewards04}
                        cardImageAltText={"Node ID form"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    We will be directed back at the home page.  However, our <span className="boldLightBlue">Node ID</span> should
                    now be populated under the <span className="boldLightBlue">Node ID</span> section.

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    <div className="h2em" />

                    Let's understand the rules, before we go any further.
                    <div className="h1em" />
                    <div className="colorLightBlue">
                        Rewards are a <span className="boldWhite">thank you</span> for your hard work, effort, respect, proper etiquette, and patience during all the time
                        we have been together within the <span className="boldWhite">DAG Chad Operator</span> community and together in the TestNet Discord channel home.
                    </div>

                    <div className="h1em" />
                    <div className="rulesBox">
                        <ul>
                            <li>You must be an invited and registered TestNet Operator (via an invite from Node Garage), to earn rewards.</li>
                            <div className="hp8em" />
                            <li><span className="boldRed">ONE</span> TestNet Node per Node Operator is allowed, to earn rewards.</li>
                            <div className="hp8em" />
                            <li>Your Node must be online, to earn rewards.</li>
                            <div className="hp8em" />
                            <li>Node Operators must keep up with the requests and instructions as needed, to earn rewards.</li>
                            <div className="hp8em" />
                            <li>You need to register your Node with Lattice, to earn rewards.</li>
                            <div className="hp8em" />
                            <li>You must have participated in TestNet for a minimum of 2 months.</li>
                            <div className="hp8em" />
                            <li>When registering with Lattice, you must have a valid digital certificate (token) that has
                                been signed by Node Garage, to earn rewards.</li>
                        </ul>
                    </div>

                    <div className="h1em" />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction05}
                        cardImageTitle="ACCESS FORM"
                        cardImageSubTitle="Choose item"
                        cardImageLocation={Rewards05}
                        cardImageAltText={"Node certificate form"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction06}
                        cardImageTitle="OUR REQUEST FORM"
                        cardImageSubTitle=""
                        cardImageLocation={Rewards06}
                        cardImageAltText={"Node certificate form2"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction07}
                        cardImageTitle="REQUEST A CERTIFICATE"
                        cardImageSubTitle="click the button"
                        cardImageLocation={Rewards07}
                        cardImageAltText={"Node certificate button click"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction08}
                        cardImageTitle="CERTIFICATE CREATED"
                        cardImageSubTitle="and populated"
                        cardImageLocation={Rewards08}
                        cardImageAltText={"Node certificate 01"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction09}
                        cardImageTitle="REQUEST A CERTIFICATE"
                        cardImageSubTitle="click the button"
                        cardImageLocation={Rewards09}
                        cardImageAltText={"Node certificate button click"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    <InstructionWithPic instruction={instruction10}
                        cardImageTitle="REQUEST A CERTIFICATE"
                        cardImageSubTitle="click the button"
                        cardImageLocation={Rewards10}
                        cardImageAltText={"Node certificate button click"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />


                    <PageTitle title="FINAL STEPS" type={2} />

                    <div className="h1em" />
                    <div className="boldRed">
                        Your token is <span className="boldWhite">valid</span> for <span className="boldWhite">2 weeks</span>
                    </div>

                    <div className="h1em" />

                    <div className="colorWhite">
                        After this period expires; <span className="boldLightBlue">if you haven't yet used it</span>, you will need to create a
                        {' '}<span className="boldLightBlue">new</span> certificate.
                    </div>

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        You will only need to do this process <span className="boldWhite">once</span>, unless your Node changes.{' '}
                    </div>

                    <div className="h1em" />

                    <span className="boldRed">
                        You can only have one Node per operator
                    </span>, so you will have to invalidate your original node (if it is registered with Lattice)
                    before you can register a new one.

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        Each Node registration requires a valid Token.
                    </div>

                    <div className="h1em" />

                    <PageTitle title="YOU ARE READY" type={2} />

                    <div className="h1em" />

                    Copy your newly created <span className="boldLightBlue">digitally signed certificate</span>
                    {' '} to your clipboard and proceed to the
                    {' '}<span className="boldLightBlue">Lattice Web Site</span>{' '}
                    website to enter in your Node's digital certificate and start to earnings
                    your rewards by associating your Node with your <span className="boldLightBlue">$DAG wallet</span>!
                    The next set of instructions are <ImPointDown /> here.

                    <div className="hp5em" />

                    <Button type="button" onClick={
                        () => window.open(
                            'https://intercom.help/constellation-network/en/articles/6187159-how-do-i-register-my-testnet-node-into-lattice-for-rewards',
                            '_blank'
                        )}
                    >
                        <div className="boldWhite font1em">
                            LATTICE EXCHANGE REWARDS ENTRY INSTRUCTIONS
                        </div>
                    </Button>
                </div>

            </div>
            <TopOfPageLink hash="#rewardsTop" />

        </>

    );
}

export default Rewards;








