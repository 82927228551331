import { useConfig } from "../../utils/Configuration";

import PageTitle from "../../components/PageTitle";
import TopOfPageLink from "../../components/TopOfPageLink";

import { Button } from "react-bootstrap";


function DocIndex() {

    const config = useConfig()
    const configurationState = config.providerInfo

    return (
        <>
            <div id="docTop">
                <PageTitle title="DOCUMENTATION INDEX" type={1} />

                <div className="textAlignLeftPad w-65">

                    <div className='h2em' />

                    <span className="boldLightBlue">
                        Constellation Validator Nodes
                    </span>
                    {' '} specifics:
                    <div className="hp2em" />

                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes', '_blank');
                        }}>
                        BUILD A CONSTELLATION VALIDATOR NODE
                    </Button>
{' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes/resources/p12backup-win', '_blank');
                        }}>
                        BACKUP RESTORE P12 FILES - WINDOWS
                    </Button>
{' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes/resources/p12backup-mac', '_blank');
                        }}>
                        BACKUP RESTORE P12 FILES - MAC
                    </Button>
{' '}
                    <Button
                        onClick={() => {
                            window.open('/docs/do-troubleshoot', '_blank');
                        }}>
                        DIGITAL OCEAN BRICK RECOVERY
                    </Button>



                    <div className="h1em" />
                    Everything {' '}
                    <span className="boldLightBlue">
                        Constellation Network
                    </span>
                    :
                    <div className="hp2em" />
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/', '_blank');
                        }}>
                        CONSTELLATION NETWORK DOCUMENTS HUB
                    </Button>
                    {' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/core-concepts/concepts', '_blank');
                        }}>
                        CONSTELLATION CONCEPTS
                    </Button>
                    {' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/core-concepts/network/state-channels', '_blank');
                        }}>
                        CONSTELLATION STATE CHANNELS
                    </Button>
                    {' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/core-concepts/network/l0-token-standard', '_blank');
                        }}>
                        CONSTELLATION L0 TOKEN STANDARD
                    </Button>
                </div>

            </div>
            <TopOfPageLink hash="#docTop" />

        </>

    );
}

export default DocIndex;








