import React from 'react'
import { ReactComponent as NGLogo } from '../images/NgLogoSvg.svg';

function Logo() {
    return (
        <div>
            <NGLogo style={{ height: '6em' }} />
        </div>
    )
}

export default Logo