import { useConfig } from "../../utils/Configuration";
import PageTitle from "../../components/PageTitle";
import TopOfPageLink from "../../components/TopOfPageLink";
import { HashLink } from 'react-router-hash-link';

import { Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import CodeSnippet from "../../components/CodeSnippet";

function RecoverNode() {

    const config = useConfig()
    const configurationState = config.providerInfo

    return (
        <>
            <div id="recoveryTop">
                <PageTitle title="FULLY RECOVER YOUR VALIDATOR NODE" type={1} />

                <div className='hp6em' />

                <div className="textAlignLeftPad w-65">
                    <div className="font-p5em">
                        Quick Link Jump To<br />
                        <HashLink smooth to="#step1recover">BUILD VPS</HashLink><br />
                        <HashLink smooth to="#step2recover">REINSTALL NODECTL</HashLink><br />
                        <HashLink smooth to="#step3recover">REINSTALL TESSELLATION</HashLink><br />
                        <HashLink smooth to="#step4recover">RECOVERY P12</HashLink><br />
                        <HashLink smooth to="#step5recover">RESTART NODE</HashLink><br />
                    </div>
                </div>

                <div className="textAlignLeftPad w-65">

                    <div className='h1em' />

                    Believe it or not your{' '}
                    <span className="boldLightBlue">
                        Constellation Validator Node
                    </span>
                    {' '}is a pretty simple{' '}
                    <Tooltip title="Virtual Private Server">
                        <span>VPS</span>
                    </Tooltip> or Bare Metal system.

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        <span className="boldWhite">Almost</span> everything that need to run your Node is standard
                        based off of <span className="boldWhite">Debian Packages</span>.
                        These packages are managed by the core default repositories obtained via Debian's version of{' '}
                        <span className="boldWhite">
                            <Tooltip title="Advanced Package Tool">
                                <span>APT</span>
                            </Tooltip>
                        </span>.  Running on top of this are the {' '}
                        <span className="boldWhite">Constellation Network Tessellation Binaries</span>
                        {' '} on top of everything else.
                    </div>

                    <div className="h1em" />
                    The core job of your <span className="boldLightBlue">Validator Node</span> is to
                    {' '}<span className="boldLightBlue">Validate</span>.   However, the data that it is
                    validating is <span className="boldLightGreen">ephemeral</span>, stored in the blockchain,
                    or elsewhere.  You do <span className="boldLightBlue">NOT</span> need to retain any data
                    that might be present on your Node, during a recovery.

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        Understanding this, can make it <span className="boldWhite">quite easy</span> to understand that
                        you do not need to retain any real information to recovery a <span className="boldWhite">Node</span>
                        {' '}that might break, or (for other reasons) may need to be rebuilt.
                    </div>

                    <div className="h1em" />

                    All we need is our{' '}
                    <Tooltip title="p12">
                        <span className="boldLightBlue">PRIVATE KEY FILE</span>
                    </Tooltip>.
                    {' '}However, this file is <span className="boldRed">EXTREMELY</span> important.  Losing this file is like{' '}
                    <span className="boldRedf">LOSING THE KEYS TO YOUR KINGDOM</span>.  In a <span className="boldLightBlue">DeFi</span>
                    {' '}no one is going to come to your rescue!

                    <div className="h1em" />

                    <div className="boldWhite">Node Recovery Steps</div>
                    <div className="colorLightBlue">
                        <ul>
                            <li>Build a new VPS</li>
                            <li>Download the latest version of <span className="boldWhite">nodectl</span></li>
                            <li>Install <span className="boldWhite">Tessellation</span> using nodectl</li>
                            <li>Transfer our P12 back onto our VPS</li>
                        </ul>
                    </div>

                    <div className="h1em" id="step1recover" />

                    <PageTitle title="STEP 1 - BUILD VPS" type={2} />
                    The following documentation will show you the full process of installing
                    your Node.  You do not need to go through the entire process.  All you need
                    to do is bring up your VPS in the cloud provider for your Choice.
                    <div className="hp2em" />
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes/validator/providers', '_blank');
                        }}>
                        BUILD A CONSTELLATION VALIDATOR NODE VPS
                    </Button>

                    <div className="h1em" id="step2recover" />

                    <PageTitle title="STEP 2 - NODECTL" type={2} />
                    After you bring up your new VPS and you are able to access it.  You are ready to install
                    {' '}<span className="boldLightBlue">nodectl</span> and turn your VPS back into
                    a Validator Node.
                    <div className="hp2em" />
                    <Button
                        onClick={() => {
                            window.open('https://nodegarage.io/nodectl/install', '_blank');
                        }}>
                        INSTALL NODECTL
                    </Button>

                    <div className="h1em" id="step3recover" />

                    <PageTitle title="STEP 3 - TESSELLATION" type={2} />
                    Using <span className="boldLightBlue">nodectl</span> you are going to issue
                    the commands to install <span className="boldLightBlue">Tessellation</span>.
                    <div className="hp5em" />
                    <span className="boldRed">IMPORTANT</span>, during the installation you want to
                    make sure that you use the <span className="boldLightGreen">EXACT</span> same
                    name for your <span className="boldLightBlue">p12</span> as your backup copy.
                    <div className="hp5em" />
                    <span className="boldRed">IMPORTANT</span>, during the installation you want to
                    use the <span className="boldLightGreen">EXACT</span> same
                    {' '}<span className="boldLightBlue">passphrase</span> as your backup copy of
                    your p12 file.

                    <CodeSnippet snipFontSize=".6em" code="sudo nodectl" />
                    <div className="hp1em" />
                    <CodeSnippet snipFontSize=".6em" code="INSTALL TESSELLATION" />
                    <div className="hp2em" />

                    <div className="hp5em" />
                    Upon completion your Node will not be 100% ready; but we are almost done.

                    <div className="h1em" id="step4recover" />

                    <PageTitle title="STEP 4 - RECOVER P12" type={2} />
                    Now that everything is place.  We setup all our Node variables correctly 
                    by using a simple trick of using the same <span className="boldLightBlue">p12</span>
                    {' '}name and passphrase.  We are ready to recovery our {' '}
                    <span className="boldLightBlue">p12</span> file.
                    <div className="hp2em" />
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes/resources/p12backup-win', '_blank');
                        }}>
                        RECOVERY OUR P12 - WINDOWS
                    </Button>
                    {' '}
                    <Button
                        onClick={() => {
                            window.open('https://docs.constellationnetwork.io/nodes/resources/p12backup-mac', '_blank');
                        }}>
                        RECOVERY OUR P12 - MAC
                    </Button>

                    <div className="h1em" id="step5recover" />

                    <PageTitle title="STEP 5 - RESTART NODE" type={2} />
                    Using <span className="boldLightBlue">nodectl</span> you are going to issue
                    the commands to restart <span className="boldLightBlue">Tessellation</span>.
                    <div className="hp5em" />
                    <CodeSnippet snipFontSize=".6em" code="sudo nodectl restart" />
                    <div className="hp1em" />

                </div>

                <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                Congratulations, you are done!
                <div className="h1em" />
                <span className="boldWhite">Thank you</span>!






                <TopOfPageLink hash="#recoveryTop" />
            </div >

        </>

    );
}

export default RecoverNode;








