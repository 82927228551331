import { useConfig } from '../utils/Configuration'
import { ReactComponent as NGLogo } from '../images/NgLogoSvg.svg';

import LeftSideNav from './LeftSideNav'
import TopMenu from './TopMenu'

function MainReadMeWindow(props) {
  const config = useConfig()
  const { page } = props

  return (
    <>
      <TopMenu />
      <div className="navigationFlex">
        <div className="navigationLeftSide">
          <div className={config.detectMobile.isMobile() ? "navMobile" : "navNotMobile"}>
            <LeftSideNav />
          </div>
        </div>
        <div className="navigationRightSide">
          <NGLogo
            className={config.detectMobile.isMobile() ?
              "backgroundLogoRightPanelMobile" :
              "backgroundLogoRightPanel"} />
          <section id="mainSection" className="fade-in">
            {page}
          </section>
        </div>
      </div>
    </>
  )
}

export default MainReadMeWindow