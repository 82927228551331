import React from 'react'

import '../css/App.css';
import '../css/Main.css';

import TopMenu from '../components/TopMenu';
import FrontPage from './FrontPage';

export default function Main() {
    return (
        <>
            <TopMenu />
            <FrontPage />
        </>
    )
}
