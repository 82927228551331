import React from 'react'

import { useConfig } from '../utils/Configuration'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

function NextButtonCloudService(props) {

    const config = useConfig()
    const navigate = useNavigate()

    var { nextName, nextLink, 
        butWidth, center,
        skipNext } = props

    if (butWidth === undefined) {
        butWidth = "40vw"
    }

    if (center){
        center = "textAlignCenter"
    }

    const handleClick = (target) => {
        config.setMenuSelection({
            title: target.name,
            link: target.id
        })
        config.populateProviderDetails(target.id)
        navigate(target.id)
    }

    return (
        <>
            <div className={center}>
                <Button type="button"
                    className="whiteOnWhiteBoxShadow"
                    style={{ width: config.detectMobile.isMobile() ? "60vw" : butWidth, fontWeight: "800" }}
                    variant="primary"
                    id={nextLink} name={nextName}
                    onClick={e => {
                        handleClick(e.target)
                    }}
                >
                    {!skipNext && "NEXT - "} {nextName.toUpperCase()}
                </Button>
            </div>
        </>
    )
}

export default NextButtonCloudService