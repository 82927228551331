import { useEffect } from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";

import Tooltip from '@mui/material/Tooltip';
import NGLogo from "../images/NGlogoWhite.png"
import LoginButtons from "./LoginButtons";

import { FaRegUserCircle } from 'react-icons/fa';
import { BiLogOutCircle } from 'react-icons/bi';

import { useAuth } from '../utils/Auth';
import { useConfig } from "../utils/Configuration";
import { useNavigate, useLocation } from 'react-router-dom'

function TopMenu() {

    const config = useConfig()
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    const redirectPath = location.state?.path || "/"

    const handleClick = (e) => {
        navigate(redirectPath, { replace: true })
    }
    const handleLogOut = () => {
        config.setDisclaimer(false)
        auth.logout().then(function(){
            navigate("/")
        })
    }

    useEffect(() => {}, [auth?.isValidated])

    return (
        <div className="topFullWidth bringToFront">
            <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" className="py-0">
                <Container>
                    <Navbar.Brand to="home" style={{ cursor: "pointer" }} onClick={e => handleClick(e)}>
                        <Navbar.Brand>
                            <img
                                src={NGLogo}
                                width="40"
                                height="40"
                                className="d-inline-block align-center"
                                alt="Node Garage Logo"
                                id="home"
                                onClick={() => navigate("/home")}
                            /> Node Garage
                            <span className="fontVmMinus1p5">
                                {!config.detectMobile.isMobile() && (
                                    <span onClick={() => navigate("/home")}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        Validator Full Service Solution
                                    </span>)}
                            </span>
                        </Navbar.Brand>
                        <Navbar.Brand>
                            <span id="home" style={{ cursor: "pointer" }}>
         </span>
                        </Navbar.Brand>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            {/* <Nav.Link eventKey={1} href="/home">
                                <Tooltip title="Return to the Home Page">
                                    <span id="home" 
                                    onClick={() => navigate("/home")}
                                    className='navHomeButton'>Home</span>
                                </Tooltip>
                            </Nav.Link> */}

                            {/* <Nav.Link eventKey={2} href="#pricing">
                            Team
                        </Nav.Link> */}

                            {/* <Nav.Link eventKey={3} onClick={handleClick}>
                            <Tooltip title="Review Node Garage Pricing Information">
                                <span id="home">Pricing</span>
                            </Tooltip>
                        </Nav.Link> */}
                            {/* 
                            <Nav.Link>
                               <span className="topUserNameDisplay">
                                   <Tooltip title={<span>Discord: {loggedIn.discord}</span>}>
                                      <span><FaRegUserCircle /> {loggedIn.email} </span>
                                    </Tooltip>
                                </span>
                                &nbsp;&nbsp;
                                <Tooltip title="logout">
                                    <span><BiLogOutCircle onClick={logout} /></span>
                                </Tooltip>
                            </Nav.Link>  */}

                            { !auth.isValidated ? <LoginButtons /> :
                            
                                <Nav.Link>
                                    <span className="topUserNameDisplay">
                                        <Tooltip title={
                                            <span>
                                                <div>Discord:&nbsp;&nbsp;&nbsp; {auth.loggedIn.discord}</div>
                                                <div>Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {auth.loggedIn.email}</div>
                                                <div>Node ID:&nbsp;&nbsp; {auth.loggedIn.nodeId}</div>
                                            </span>}>
                                            <span>
                                                <FaRegUserCircle size={25} />
                                                &nbsp;&nbsp;
                                                {auth.loggedIn.discord}
                                            </span>
                                        </Tooltip>
                                    </span>
                                    &nbsp;&nbsp;
                                    <span onClick={handleLogOut}>
                                        <Tooltip title="logout">
                                            <span>
                                                &nbsp;&nbsp;
                                                <BiLogOutCircle size={25} />
                                            </span>
                                        </Tooltip>
                                    </span>
                                </Nav.Link>
                            }

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>

    );
}

export default TopMenu;