import React from "react";
import SideImageCard from "./SideImageCard";
import { Col, Row } from "react-bootstrap";
import { useConfig } from "../utils/Configuration";

import Box from '@mui/material/Box';

export default function InstructionWithPic(props) {

    const config = useConfig()

    const {
        instruction,
        cardImageTitle,
        cardImageSubTitle,
        cardImageLocation,
        cardImageAltText,
        mobileWidth,
        desktopWidth } = props;

    //let rowSpan = 10

    //let sideMobileWidth = mobileWidth-5
    let sideDesktopWidth = desktopWidth-1

    return (
        <div className="whiteOnBlackBoxShadow" >

            <Row className="justify-content-md-center align-items-center">
                <Col md={{ span: 11, offset: 0 }} xs={{ span: 11, offset: 0 }}>
                    <div>
                        {instruction}
                    </div>
                </Col>
            </Row>

            <div className="hp5em"></div>

            <Row>
                <Col>
                    <Box
                        sx={{
                            width: config.detectMobile.isMobile() ? mobileWidth+"vw" : desktopWidth+"vw",
                            borderRadius: 2,
                            padding: 0,
                            color: "black",
                            fontWeight: 500,
                            fontSize: 19,
                            backgroundColor: 'white',
                        }}
                    >

                        <div className="hp7em"></div>

                        <SideImageCard Title={cardImageTitle}
                            SubTitle={cardImageSubTitle}
                            Image={cardImageLocation}
                            ImageAltText={cardImageAltText}
                            config={config}
                            mobileWidth={sideDesktopWidth}
                            desktopWidth={sideDesktopWidth}
                        />

                    </Box>
                </Col>
            </Row>
            
            <div className="hp5em"></div>
        </div>
    )
}
