import React from "react";

// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../utils/Auth";
import { useConfig } from "../../../../utils/Configuration";

import { Button, Badge } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

import PageTitle from "../../../../components/PageTitle";
import NextButtonCloudService from "../../../../components/NextButtonCloudService";
import TopOfPageLink from "../../../../components/TopOfPageLink";
import CodeSnippet from "../../../../components/CodeSnippet";

import { ImPointUp, ImPointDown } from 'react-icons/im';
import { FiFrown } from 'react-icons/fi';
import { MdKeyboardReturn } from 'react-icons/md';

import MacTerminal01 from '../../../../images/mac-terminal-01.png';
import MacTerminal02 from '../../../../images/mac-terminal-02.png';
import Sftp01 from '../../../../images/mac-sftp-01.png';
import Sftp02 from '../../../../images/mac-sftp-02.png';
import Sftp03 from '../../../../images/mac-sftp-03.png';
import Sftp04 from '../../../../images/mac-sftp-04.png';
import Sftp05 from '../../../../images/mac-sftp-05.png';
import Sftp06 from '../../../../images/mac-sftp-06.png';
import Sftp07 from '../../../../images/mac-sftp-07.png';

function DigitalOceanImageMac(props) {

    const config = useConfig()
    const { type } = props

    var startUser = "nodeadmin"
    var endUser = "root"
    var dragSide = "left"
    var dragStart = "right"

    if (type === "restore") {
        startUser = "root"
        endUser = "nodeadmin"
        dragSide = "right"
        dragStart = "left"
    }

    let nextLink = "/do/launch-droplet"
    let nextName = "Digital Ocean launch droplet"

    return (
        <>
            <div className="tabInsideStyle" id="MacNodeTop">
                We will now <span className="boldLightBlue">{type}</span> our files via <span className="boldLightBlue">Apple</span>
                {' '} or <span className="boldLightBlue">Linux</span>.

                <div className="h1em" />

                In order to save time and effort, since <span className="boldLightBlue">Macintosh</span> (Apple) has
                a <span className="boldLightBlue">Linux</span> OS "under-belly", and most
                Linux distributions (that Node Administrators should be using) come with
                a <span className="boldLightBlue">Secure File Transfer Protocol</span>
                {' '}command-line tool built-in, we will use this.

                <div className="h1em" />

                If you have a GUI application
                such as <span className="boldLightBlue">Filezilla</span>, you are free to use it
                at your own leisure.

                <div className='h1em' />

                On your Macintosh open up a new terminal, by first clicking anywhere your Desktop to reveal the
                Apple main menu.  Next, choose
                {' '}<span className="boldLightBlue">Go</span> from the top menu and then <span className="boldLightBlue">Utilities</span>
                {' '}from the list.

                <div className='hp5em' />

                <img src={MacTerminal01} alt="open terminal 1"
                    className="nnodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Select the <span className="boldLightBlue">Terminal</span>.

                <div className='hp5em' />

                <img src={MacTerminal02} alt="open terminal 1"
                    className="nnodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Linux users, select
                {' '}<span className="boldLightBlue">Control-Alt-T</span> or open the <span className="boldLightBlue">search</span>
                {' '}from <span className="boldLightBlue">Dash</span>, and type in <span className="boldLightBlue">terminal</span>.

                <div className='h1em' />

                When we open up our terminal, we will be automatically directed to our
                user's <span className="boldLightBlue">home</span> directory.

                {type === "backup" ?
                    <>
                        {' '}From here let's create a new directory
                        to hold only our backup files, and change to that directory.
                    </>
                    :
                    <>
                        <div className='h1em' />
                        Change directories to our backup directory (if not already there).
                    </>}

                {type === "backup" ?
                    <>
                        <CodeSnippet snipFontSize=".6em" code="mkdir constellation_backup" />
                        If the command causes your system to create a new line with a <span className="boldLightBlue">%</span>
                        {' '}(or similar prompt){' '}and no error message or other messages, it was successful.  If not, make sure the command you
                        entered was correct.
                    </> : ""}
                <CodeSnippet snipFontSize=".6em" code="cd constellation_backup" />

                <div className='hp5em' />

                <img src={type === "backup" ? Sftp02 : Sftp07} alt="sftp 2"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Similar to how your have been taught in prior documentation on <span className="boldLightBlue">Node Garage</span>,
                {' '}we are going to <span className="boldLightBlue">sftp</span> instead of <span className="boldLightBlue">ssh</span>
                {' '}into our Droplet.

                <div className='hp5em' />

                Since you have been accessing your <span className="boldLightBlue">Node</span> for some time now (before the Node got corrupted), the procedure to
                enter into the <span className="boldLightBlue">sftp</span> console, is the <span className="boldLightGreen">EXACT</span>
                {' '}same as you have been doing in order to access your Node (which was by opening an <span className="boldLightBlue">SSH</span>
                {' '}session to your remote Droplet).  The only difference is the word <span className="boldLightBlue">sftp</span> instead
                of <span className="boldLightBlue">ssh</span>.

                <div className='hp5em' />

                To access our <span className="boldLightBlue">Node</span> as you always have done in the past (ssh key and ip address are made up here)
                <CodeSnippet snipFontSize=".6em" code="ssh -i ~/.ssh/my-ssh-rsa-private-key root@111.111.111.111" />
                This will change to
                <CodeSnippet snipFontSize=".6em" code="sftp -i ~/.ssh/my-ssh-rsa-private-key root@111.111.111.111" />

                <div className='hp5em' />

                If you do <span className="boldRed">not</span> have your <span className="boldLightBlue">ssh</span> private key in your <span className="boldLightBlue">.ssh</span> directory it is probably in
                the root of your <span className="boldLightBlue">home</span> directory.

                To access our Node as you always have done in the past (ssh key and ip address are made up here)
                <CodeSnippet snipFontSize=".6em" code="ssh -i ~/my-ssh-rsa-private-key root@111.111.111.111" />
                This will change to
                <CodeSnippet snipFontSize=".6em" code="sftp -i ~/my-ssh-rsa-private-key root@111.111.111.111" />

                <div className='h1em' />

                We are accessing our{' '}
                <span className="boldLightBlue">
                    {type === "backup" ? "broken" : "rebuilt"}
                </span>{' '}
                Droplet as{' '}
                <span className="boldLightBlue">
                    {type === "backup" ? "root" : "nodeadmin"}
                </span>.

                <div className='hp5em' />
                We need to make sure we obtain a <span className="boldLightBlue">sftp{'>'}</span> prompt.
                <div className='hp5em' />

                <img src={Sftp06} alt="sftp 3"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                In this example, we are calling our <span className="boldLightBlue">p12</span> file{' '}
                <span className="boldLightGreen">myp12file.p12</span>.  You will need to <span className="boldRed">replace</span>
                {' '}this file with the actual name of your <span className="boldLightBlue">p12</span> file. We will use the
                following commands once we are authenticated and are at
                a <span className="boldLightBlue">sftp{'>'}</span> prompt.

                <div className='hp5em' />

                {type === "backup" ?
                    <>
                        <CodeSnippet snipFontSize=".6em" code="get /mnt/home/nodeadmin/tessellation/myp12file.p12" />
                        <CodeSnippet snipFontSize=".6em" code="get /mnt/usr/local/bin/cn-node" />
                    </> :
                    <>
                        <CodeSnippet snipFontSize=".6em" code="put myp12file.p12 /home/nodeadmin/tessellation/" />
                        <CodeSnippet snipFontSize=".6em" code="put cn-node /home/nodeadmin/" />
                    </>}

                <div className='hp5em' />

                When we see the <span className="boldLightBlue">100%</span>, this means that our file
                has successfully download (100% done)

                <div className='hp5em' />

                <img src={type === "backup" ? Sftp03 : Sftp04} alt="sftp 4"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='hp5em' />

                {type === "backup" ?
                    <>
                        <span className="font-p5em">the lower-case letter L <span className="boldRed">not</span> the number 1</span>
                        <CodeSnippet snipFontSize=".6em" code="quit" />
                        <CodeSnippet snipFontSize=".6em" code="ls -l" />

                        You should now see in your <span className="boldLightBlue">constellation_backup</span> directory
                        the two files you saved from your <span className="boldLightBlue">broken</span> Node.
                    </>
                    :
                    <>
                        Since our Node has been restored, and we should have our <span className="boldLightBlue">Droplet</span> turned
                        back into a <span className="boldLightBlue">Node</span>, we won't have access to our <span className="boldLightBlue">root</span>
                        {' '} user from <span className="boldLightBlue">sftp</span>.  We temporarily placed our original
                        {' '}<span className="boldLightBlue">cn-node</span> in the <span className="boldLightBlue">nodeadmin</span>
                        {' '}home directory.  We are done, and we can quit out of <span className="boldLightBlue">sftp</span>.

                        <CodeSnippet snipFontSize=".6em" code="quit" />
                    </>}


                <div className='hp5em' />

            </div>
            <TopOfPageLink hash="#MacNodeTop" />
        </>

    );
}

export default DigitalOceanImageMac;








