import { useState, useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import { useAuth } from '../utils/Auth';
import { useConfig } from '../utils/Configuration';

import { Button } from 'react-bootstrap';
import { GiDiploma } from 'react-icons/gi';

function NodeDigitalCert() {
    const auth = useAuth();
    const config = useConfig();

    const [digSig, setDigSig] = useState(undefined)
    const [digSigVerified, setDigSigVerified] = useState("no results")

    var nodeId
    if (auth.loggedIn.nodeId) {
        nodeId = auth.loggedIn.nodeId
    } else {
        nodeId = "Not Found on Server"
    }

    const handleCreateSig = () => {
        if (auth.enableRewards) {
            setDigSigVerified("no results")
            auth.handleToken({
                type: "n"
            }).then(function (response) {
                if (response) {
                    setDigSig(response.token.split(' ')[1])
                } else {
                    setDigSig(`An invalid login was detected or your session has 
                timed out. Please contact support if you feel you reached this 
                message in error.  Thank you.`)
                }
            });
        } else {
            alert("You have not met the proper criteria to receive rewards.\n\nPlease contact a Systems Administrator if you feel you received this message in error.")
        }
    }

    const verifySig = () => {
        auth.verifyToken({
            type: "n",
            token: `Bearer ${digSig}`
        }).then(function (response) {
            setDigSigVerified(response.success ? "verified" : "failed")
        });
    }

    var verifyClass = "digTokenVerify "
    if (digSigVerified === "verified") {
        if (config.detectMobile.isMobile()) {
            verifyClass += "vSuccess"
        } else {
            verifyClass += "vSuccess digTokenVerifyDesktopPad"
        }
    } else if (digSigVerified === "failed") {
        if (config.detectMobile.isMobile()) {
            verifyClass += "vFail"
        } else {
            verifyClass += "vFail digTokenVerifyDesktopPad"
        }
    }

    useEffect(() => {
    }, [digSig, digSigVerified])

    return (
        <div className="textAlignCenter w-65">

            <div>
                <PageTitle title="Request Digital Certification" type={2} />
            </div>

            <div className="h1em" />

            <div className="font-p8em">
                Create request for Node ID <GiDiploma size={32} />
            </div>

            <div className="hp2em" />

            <div className="paddedLightBlueBox font-p5em textAlignCenter">
                <span className={nodeId === "Not Found on Server" ? "colorRed" : "boldLightBlue"}>
                    {nodeId}
                </span>
            </div>

            <div className="h2em" />

            <div className='textAlignCenter'>
                <Button type="button"
                    className="whiteOnWhiteBoxShadow"
                    style={{ width: config.detectMobile.isMobile() ? "60vw" : "40vw", fontWeight: "800" }}
                    variant="primary"
                    id="createCert" name="createCert"
                    onClick={() => {
                        handleCreateSig()
                    }}
                >
                    CREATE DIGITAL TOKEN
                </Button>
            </div>

            <div className="h1em" />

            <div id="digitalResults"
                className="paddedLightGreenBox font-p5em h25em textAlignLeft">
                <span className="textAlignMiddle">{digSig}</span>
            </div>

            <div className="hp2em" />

            <div className="font-p4em">
                This digital signed token will be valid for two weeks.
            </div>

            <div className="h1em" />
            <div>
                <Button type="button"
                    className="whiteOnWhiteBoxShadow"
                    style={{ width: "120px" }} size="sm"
                    id="createCert" name="createCert"
                    onClick={() => {
                        verifySig()
                    }}
                >
                    <span className="colorWhite">VERIFY TOKEN</span>
                </Button>
                {config.detectMobile.isMobile() ?
                    <>
                        <div className="hp3em" />
                        <div className={verifyClass}>
                            {digSigVerified}
                        </div>
                    </> :
                    <span>{' '}&nbsp;
                        <span className={verifyClass}>
                            {digSigVerified}
                        </span>
                    </span>
                }

            </div>
        </div >

    )
}

export default NodeDigitalCert