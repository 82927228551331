import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useAuth } from "./Auth";

export const RequiredAuth = ({ children }) => {

  const auth = useAuth()
  const navigate = useNavigate()

  const [isRestricted, setIsRestricted] = useState(false)

  const updateIsRestricted = (trueOrFalse) => {
    if(trueOrFalse){
      auth.setIsValidated(false)
      auth.setExtTokenVerified(false)
      setIsRestricted(true)
    } else {
      auth.setIsValidated(true)
      auth.setExtTokenVerified(true)
      setIsRestricted(false)
    }
  }
  
  useEffect(() => {
    if (!auth.accessToken) {
      auth.handleExternalVerification().then(function (token) {
        if(!token){
          updateIsRestricted(true)
        }
        auth.verifyToken({
          token: token,
          type: "r"
        }).then(function(response){
          if (response.success) {
            auth.updateUser(response)
            updateIsRestricted(false)
          } else {
            updateIsRestricted(true)
          }
        })
      })
    } else {
      auth.verifyToken({
        type: "m"
      }).then(function(response){
        if (response.success) {
          updateIsRestricted(false)
        } else {
          updateIsRestricted(true)
        }
      });
    }
  }, [])

  useEffect(() => {
    if (isRestricted) {
      auth.logout().then(function(){
        navigate("/login")
      })
    }
  }, [isRestricted])


  return children

}


