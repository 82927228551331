import React from 'react'
import { Button } from 'react-bootstrap'
import { BiChevronsUp } from 'react-icons/bi'
import { HashLink } from 'react-router-hash-link';
import Tooltip from '@mui/material/Tooltip';

function TopOfPageLink(props) {
    var { hash, tip, location } = props
    var whatClass
    var divClass

    if(tip === undefined){
       tip = "Back to Top"
    }

    if(location === "inside"){
        divClass = "textAlignRightPad"
    } else {
        whatClass = "scrollToTopButton"
    }
    
    return (
        <div className={divClass}>
            <Tooltip title={tip}>
                <Button variant="outline-secondary" size="sm" className={whatClass}>
                    <HashLink smooth to={hash}>
                        <BiChevronsUp className="boldRed" size={25} />
                    </HashLink>
                </Button>
            </Tooltip>
        </div>
    )
}

export default TopOfPageLink