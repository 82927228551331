import React from 'react'
import Youtube from "../components/YouTube";
import PageTitle from '../components/PageTitle';
import { Badge } from 'react-bootstrap';
import TopOfPageLink from '../components/TopOfPageLink';

function YouTubeSeries() {

    return (
        <div id="youtubeTop">
            <PageTitle title="VIDEO SERIES BY NETMET" />
            <div className='h1em' />
            <div className="textAlignLeftPad w-65">
                <div>
                    The following <span className="boldWhite">YouTube</span> series;
                    created by Node Garage and
                    Constellation's <span className="boldWhite">netmet</span>.
                </div>

                <div className='h1em' />

                <div className="colorLightBlue">
                    The series takes you through step-by-step instructions and offers an understanding of how
                    to build and secure a cloud server.
                </div>

                <div className='h1em' />

                <div>
                    If you have chosen to build your server in the cloud, and you have
                    picked a cloud provider of your choice, you have the option of
                    following the video series below.
                </div>

                <div className='h1em' />

                <div className="colorLightBlue">
                    At the conclusion of the series, you will have your cloud server
                    created and accessible.  It is recommended to install Node
                    Garage's <span className="boldWhite">nodectl</span> to turn
                    your server into a <span className="boldWhite">Validator Node</span>.
                    The video series is
                    called <span className="boldWhite">SSH KEY SERIES</span>, however
                    it contains everything you will need to succeed at creating and
                    accessing your new cloud server, before turning your Cloud Server into
                    your <span className="boldWhite">Validator Node</span>.
                </div>

                <div className='h1em' />

                <div>
                    <Badge bg="warning">NOTE</Badge> Until <span className="boldLightBlue">TestNet</span>
                    {' '}is available to the public, videos on how to turn your Droplet, Instance, or VM into a Constellation
                    {' '}<span className="boldLightBlue">Node</span>, will not be available.
                </div>

                <div className='h1em' />

                <Youtube embedId="LMxmJ7h0RQw&list=PL-C4Em4lpOEdAgpzWApAUhzXIMa4U5amk"
                    seriesNo={0}
                    colorWhiteBlue="colorLightBlue"
                    skip={true}
                />

            </div>
            <TopOfPageLink hash="#youtubeTop" />
        </div>
    )
}

export default YouTubeSeries