import { useState } from 'react';
import PageTitle from '../components/PageTitle';
import { useAuth } from '../utils/Auth';
import { useConfig } from '../utils/Configuration';
import { useNavigate } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';

function CrudNodeId() {
  const auth = useAuth();
  const config = useConfig();
  const navigate = useNavigate();

  const errorMsg = `Error with NodeID:\n\nYour Node Content is invalid:
  - You may not have changed any data?
  - Your node ID is not in the correct format
  - Your node ID length is not 128 bytes?
 \nPlease try again!`

  const [nodeId, setNodeId] = useState(undefined)

  const validateNode = (node) => {
    var re = /[0-9A-Fa-f]{6}/g;

    if (re.test(node) && node.length === 128 && nodeId !== "Not Found on Server") {
      return true
    }
    return false
  }

  const handleNodeModification = () => {
    let crud
    if (initNodeId === "Not Found on Server") {
      crud = "insert"
    } else {
      crud = "update"
    }

    if (!validateNode(nodeId)) {
      alert(errorMsg);
    } else {
      auth.updateDatabase({
        table: 3589,
        key: "nodeid",
        data: nodeId,
        crud: crud
      }).then(function (response) {
        if (response) {
          auth.setLoggedIn({
            ...auth.loggedIn,
            nodeId: nodeId
          })
          navigate("/home", { updatedNodeId: nodeId })
        }
      });
    }
  }

  var initNodeId
  if (!auth.loggedIn.nodeId || auth.loggedIn === "Not Found on Server") {
    initNodeId = "Not Found on Server"
  } else {
    initNodeId = auth.loggedIn.nodeId
  }

  return (
    <div className="textAlignCenter w-65">

      <div>
        <PageTitle title="ADD OR UPDATE NODE ID" type={2} />
      </div>

      <div className="h1em" />

      <div className="font-p8em">
        Current Node ID <HiOutlineIdentification size={32} />
      </div>

      <div className="hp2em" />

      <div className="paddedLightGreenBox font-p5em textAlignCenter">
        {initNodeId}
      </div>
      <div className="h2em" />

      <div className="font-p8em">
        Enter or Update Node ID
      </div>

      <div className="font-p4em colorRed">
        Remove any unwanted text from the input box before continuing.
      </div>
      
      <div className="font-p4em colorYellow">
        The Node id text is centered; do not include any spaces
      </div>

      <div className="hp2em" />

      <div className="font-p5em textAlignCenter">
        {/* <input type="text" className="inputBoxDressing" defaultValue={initNodeId} onChange={(e) => setNodeId(e.target.value)} /> */}
        <textarea
          defaultValue={initNodeId}
          className="inputBoxDressing"
          rows={3}
          onChange={(e) => setNodeId(e.target.value)} />
      </div>

      <div className="h2em" />

      <div className='textAlignCenter'>
        <Button type="button"
          className="whiteOnWhiteBoxShadow"
          style={{ width: config.detectMobile.isMobile() ? "60vw" : "40vw", fontWeight: "800" }}
          variant="primary"
          id="updateNodeIdBut" name="updateNodeIdBut"
          onClick={() => {
            handleNodeModification()
          }}
        >
          ADD OR UPDATE NODE ID
        </Button>
      </div>
    </div >

  )
}

export default CrudNodeId