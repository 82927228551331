import { ReactComponent as NGLogo } from '../images/NgLogoSvg.svg';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

export default function PageNotFound() {

    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
    }
    
    return (
        <>

            <div><NGLogo className="svgSizing" /></div>
            <div className='h2em' />
            <div className='font-p6em'>
                This page was not found.
            </div>
            <div className="h1rem" />
            <div>
                <Button type="button" className="btn btn-sm" onClick={handleClick}>Return to NodeGarage</Button>
            </div>

        </>
    )
}
