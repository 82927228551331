import React from "react";
import { Badge } from "react-bootstrap";

function MobileWarning() {

    return (

        <>

            <div className="centeredFlexBoxCols font-p6em colorRed"
                 style={{border: "1px dotted yellow"}}
            >

                <div className="h1em" />
                <div className="flexElement55w">
                    We detected you are viewing this on a mobile device.
                </div>

                <div className="h1em" />
                <div className="flexElement55w">
                    It is <Badge bg="danger">NOT</Badge> recommended to go through this tutorial with a
                    mobile device as your  main viewing apparatus.  The formatting of the command line details may
                    be harder to read, comprehend, etc.
                </div>

                <div className="h1em" />
                <div className="flexElement55w">
                    Proceed with some understanding that although we attempted to make this
                    tutorial work as visually helpful as possible on Mobile, this may still
                    impede on your efforts.
                </div>

                <div className="h1em" />
                <div className="flexElement55w">
                    Viewing in landscape mode is recommended for those that do not have a
                    device with a larger viewing screen.
                </div>

                <div className="h1em" />
            </div>

        </>

    );
}

export default MobileWarning;