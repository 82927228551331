import React from 'react'
import { useConfig } from "../utils/Configuration";

function PageTitle(props) {

  const { title, type } = props
  const config = useConfig()

  let mobile_size = "1.1em"
  let desktop_size = "1.6em"

  if (type === 2){
    desktop_size = "1.1em"
  }
  return (
    <>
      <div className="centeredFlexBoxCols basicCardTitleLighterBlue whiteOnBlackBoxShadow">
        <div style={{ fontSize: config.detectMobile.isMobile() ? mobile_size : desktop_size }}>
          {title}
        </div>
        { type === 2 &&
          <hr style={{color: "rgb(113, 148, 229)", height: ".2em", width: "65vw" }} />
        }
      </div>
    </>
  )
}

export default PageTitle