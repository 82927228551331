import { useState, useContext, createContext } from "react";
import axios from '../api/axios';
import { validPassword, genPassword } from "./passwordUtil";
import { axiosPrivate } from "../api/axios";

const AuthContext = createContext(undefined)
//const djlla = "r^fknN32s1(0vXX!d"

export const AuthProvider = ({ children }) => {

    const [signUpState, setSignUpState] = useState(false)
    const [signInState, setSignInState] = useState(false)
    const [djlla, setDjlla] = useState("r^fknN32s1(0vXX!d")
    const [accessToken, setAccessToken] = useState()
    const [expiredUser, setExpiredUser] = useState(false)
    const [recoveredToken, setRecoveredToken] = useState(false)
    const [isValidated, setIsValidated] = useState(undefined)
    const [extTokenVerified, setExtTokenVerified] = useState(undefined)
    const [authParams, setAuthParams] = useState()
    const [enableRewards, setEnableRewards] = useState(0)

    const [loggedIn, setLoggedIn] = useState({
        userId: undefined,
        discord: undefined,
        email: undefined,
        nodeId: undefined,
        accessLevel: undefined,
        disclaimerAgreed: undefined
    })

    async function authUser() {
        return new Promise((resolve, reject) => {
            axiosPrivate.post('api/getUser', {
                discord: loggedIn.discord,
                djlla: djlla,
            }).then(function (response) {
                if (response.data) {
                    // retrieve user successful
                    resolve(response.data)
                } else {
                    // access denied
                    reject(false)
                }
            }).catch(function (error) {
                reject(false)
            })
        })
    }

    async function updateDatabase(req) {
        return new Promise((resolve, reject) => {

            axiosPrivate.post('api/updateDb', {
                userid: loggedIn.userId,
                djlla: djlla,
                table: req.table,
                key: req.key,
                data: req.data,
                crud: req.crud,
                headers: {
                    'content-type': 'text/json',
                    'authorization': accessToken,
                }
            }).then(function (response) {
                if (response.data) {
                    resolve(response)
                } else {
                    reject(false)
                }
            }).catch(function (error) {
                reject(false)
            })
        })
    }

    async function handleToken(req) {
        var type = req?.type
        return new Promise((resolve, reject) => {
            axiosPrivate.post('api/issueToken', {
                djlla: djlla,
                userid: loggedIn.userId,
                content: loggedIn.nodeId,
                type: type,
                headers: {
                    'content-type': 'text/json',
                    'authorization': accessToken,
                }                
            }).then(function (response) {
                if (response.data) {
                    if (req.type === "m") {
                        setAccessToken(response.data.token)
                    }
                    resolve(response.data)
                } else {
                    resolve("access denied")
                    reject(false)
                }
            }).catch(function (error) {
                resolve(false)
                reject(false)
            })
        })
    }

    async function verifyToken(req) {
        var type = req?.type
        var requestedAccessToken = accessToken

        if (type === "n" || type === "r") {
            requestedAccessToken = req.token
        }

        return new Promise((resolve, reject) => {
            axios.post('api/verifyToken', {
                djlla: djlla,
                type: type,
                discord: loggedIn.discord,
                headers: {
                    'content-type': 'text/json',
                    'authorization': requestedAccessToken
                }
            }).then(function (response) {
                if (response.data) {
                    resolve(response.data)
                } else {
                    setExtTokenVerified(false)
                    setIsValidated(false)
                    resolve(false)
                    reject(false)
                }
            }).catch(function (error) {
                setExtTokenVerified(false)
                setIsValidated(false)
                resolve(false)
                reject(false)
            })
        })
    }

    async function handleValidation() {
        return new Promise((resolve, reject) => {
            if (authParams) {
                let isValidated = validPassword(
                    loggedIn.password,
                    authParams.hash,
                    authParams.salt
                )
                setIsValidated(isValidated)
                setExtTokenVerified(isValidated)
                resolve(isValidated)
            }
        })
    }

    async function handleExternalVerification() {
        var result = false
        if (!accessToken) {
            result = await grabToken()
        }
        if (result) {
            setAccessToken(result)
            return result
        } else {
            return false
        }
    }

    async function grabToken() {
        return new Promise((resolve, reject) => {
            axiosPrivate.post('api/pullCookie', {
                djlla: djlla,
                cookie: "jwt",
            }).then(function (response) {
                var recoveredToken = response.data.cookieValue.replace("Bearer%20", "Bearer ")
                if (response.data) {
                    resolve(recoveredToken)
                } else {
                    reject("please authenticate...")
                }
            }).catch(function (error) {
                resolve(false)
            })
        });
    }

    async function prepareParameters(req){
        return new Promise((resolve, reject) => {
            setAuthParams({
                password: req.password,
                hash: req.hash,
                salt: req.salt
            });
        })
    }

    async function updateUser(req) {
        return new Promise((resolve, reject) => {
            var nodeId = req.nodeId
            if (req.nodeId === undefined || req.nodeId === "") {
                nodeId = "Not Found on Server"
            }
            if(req.data !== undefined){
                req = req.data
            }
            setLoggedIn({
                ...loggedIn,
                discord: req.discord,
                userId: req.userId,
                nodeId: nodeId,
                email: req.email,
                disclaimerAgreed: req.disclaimer
            })
            console.log(`enablerewards: ${JSON.stringify(req)}`)
            setEnableRewards(req.enableRewards)
            resolve()
        })
    }

    async function logout() {
        return new Promise((resolve, reject) => {
            setLoggedIn({})
            setIsValidated(undefined)
            setExtTokenVerified(false)
            setAuthParams({})
            axiosPrivate.post('api/removeCookie', {
                djlla: djlla,
                cookie: 'jwt'
            })
            resolve()
        })
    }

    return (
        <AuthContext.Provider value={
            {
                loggedIn, setLoggedIn,
                handleValidation,
                isValidated, setIsValidated,
                extTokenVerified, setExtTokenVerified,
                handleToken, verifyToken,
                expiredUser, logout,
                accessToken, setAccessToken, enableRewards,
                signInState, signUpState,
                setSignInState, prepareParameters,
                authUser, djlla,
                authParams, setAuthParams,
                handleExternalVerification,
                updateDatabase, updateUser,
                setRecoveredToken, recoveredToken
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}



