import React from 'react'
import { Stack } from "react-bootstrap";

function CodeSnippet(props) {

    const { code, bgColorClass, snipFontSize, wrap } = props
    
    let printCode = code.split("NEWLINE")
    let snippetClass = "bg-light border padTopBottom5 terminalCodeText colorBlack font-p8em"
    let wrapNoWarp = "nowrap"
    let overFlow = "auto"

    if (bgColorClass !== undefined){
        snippetClass = bgColorClass+" border padTopBottom5 terminalCodeText colorBlack font-p8em"
    }
    if(wrap){
        wrapNoWarp = "wrap"
        overFlow = "hidden"
    }

    return (
        <Stack gap={3} style={{ width: "95%" }}>
            <div className="hp1em" />
            <div 
            className={ snippetClass }
            style={{ overflow: overFlow, whiteSpace: wrapNoWarp, fontSize: snipFontSize }}          
            >
                {
                    printCode.map(function(item,i){
                        return <div key={i}>{item}</div>
                    })
                }
            </div>
            <div className="hp1em" />
        </Stack>
    )
}

export default CodeSnippet