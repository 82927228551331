import React from "react"
import { Routes, Route } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "./utils/Auth";
import { ConfigProvider } from "./utils/Configuration"
import { RequiredAuth } from "./utils/RequiredAuth";

import PageNotFound from "./components/PageNotFound";
import MainReadMeWindow from "./components/MainReadMeWindow";
import Main from './pages/Home';
import Disclaimer from './components/Disclaimer';
import Login from "./components/Login";
import Welcome from "./pages/Welcome";
import YouTubeSeries from "./pages/YouTubeSeries";
import NodeCtlInstall from "./pages/GroupPages/NodeCtlInstall";
import NodeCtlUsage from "./pages/GroupPages/NodeCtlUsage";
import NodeDigitalCert from "./pages/NodeDigitalCert";
import CrudNodeId from "./pages/CrudNodeId";
import Faq from "./pages/Faq";
import Rewards from "./pages/GroupPages/Rewards";
import DocIndex from "./pages/GroupPages/docHubIndex";
import P12Backup from "./pages/GroupPages/P12Backup";
import RecoveryNode from "./pages/GroupPages/RecoverNode";
import DigitalOceanTS from "./pages/GroupPages/DigitalOcean/TS/DigitalOceanTS";

function App() {

  return (
    <AuthProvider>
      <CookiesProvider>
        <ConfigProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="home" element={<Main />} />
              <Route path="disclaimer" element={
                <RequiredAuth><Disclaimer /></RequiredAuth>}
              />

              <Route path="welcome" element={
                <RequiredAuth><MainReadMeWindow page={<Welcome />} /> </RequiredAuth>}
              />

              <Route path="rewards" element={
                <RequiredAuth><MainReadMeWindow page={<Rewards />} /></RequiredAuth>}
              />

              <Route path="node">
                <Route path="nodeid-cert" element={<RequiredAuth><MainReadMeWindow page={<NodeDigitalCert />} /></RequiredAuth>} />
                <Route path="add-update-node" element={<RequiredAuth><MainReadMeWindow page={<CrudNodeId />} /></RequiredAuth>} />
              </Route>

              <Route path="docs">
                <Route path="p12backup" element={<RequiredAuth><MainReadMeWindow page={<P12Backup />} /></RequiredAuth>} />
                <Route path="recovery" element={<RequiredAuth><MainReadMeWindow page={<RecoveryNode />} /></RequiredAuth>} />
                <Route path="doc-index" element={<RequiredAuth><MainReadMeWindow page={<DocIndex />} /></RequiredAuth>} />
                <Route path="do-troubleshoot" element={<RequiredAuth><MainReadMeWindow page={<DigitalOceanTS />} /></RequiredAuth>} />
                <Route path="faq" element={<RequiredAuth><MainReadMeWindow page={<Faq />} /></RequiredAuth>} />
              </Route>

              <Route path="nodectl">
                <Route path="install" element={<RequiredAuth><MainReadMeWindow page={<NodeCtlInstall />} /></RequiredAuth>} />
                <Route path="usage" element={<RequiredAuth><MainReadMeWindow page={<NodeCtlUsage />} /></RequiredAuth>} />
              </Route>

              <Route path="youtube" element={
                <RequiredAuth>
                  <MainReadMeWindow page={<YouTubeSeries />} />
                </RequiredAuth>} />
              <Route path="login" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </ConfigProvider>
      </CookiesProvider>
    </AuthProvider>
  );
}

export default App;
