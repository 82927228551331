import MobileWarning from "../../components/MobileWarning";

import { useConfig } from "../../utils/Configuration";
import CheckList from "../../components/CheckList";
import CheckListRow from "../../components/CheckListRow";

import NodeAssumptions from "../../components/NodeAssumptions";

import NextButtonCloudService from "../../components/NextButtonCloudService";
import CodeSnippet from '../../components/CodeSnippet';
import PageTitle from "../../components/PageTitle";
import UpdateUpgrade from "../../components/UpdateUpgrade";
import TopOfPageLink from "../../components/TopOfPageLink";

import { Button, Badge, Accordion } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

import { ImPointDown } from "react-icons/im";
//import NextButtonCloudService from "../../components/NextButtonCloudService";

function NodeCtlInstall() {

    const config = useConfig()
    const configurationState = config.providerInfo

    const accordionStyle = {
        button: {
            display: "flex",
            height: 100,
            width: "60vw",
            backgroundColor: "pink",
        }
    }

    return (
        <>
            <div id="nodectlInstallTop">
                <PageTitle title="INSTALL NODECTL" type={1} />

                <div className='h1em' />

                <div className="textAlignLeftPad w-65">

                    <div className="font-p6em colorLightGreen textAlignCenter">
                        <u>Should Be Completed Already</u>
                    </div>

                    <div className="hp2em" />


                    <CheckList>
                        <CheckListRow listItem="Cloud Provider Signed Up" />
                        <CheckListRow listItem={"Created " + configurationState.instance + " on your Cloud Provider"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem={configurationState.instance + " is running"} />
                        <CheckListRow listItem={"You are able to successfully access your Node as your non-root user"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="p12 key file created" />
                        <CheckListRow listItem="Node package dependencies installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="node bash script created" />
                        <CheckListRow listItem="Tessellation package binaries installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="You are no longer using your root user" />
                        <CheckListRow listItem="Tessellation service created, enabled and started" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="Your Node is successfully joined to the TestNet" />
                    </CheckList>

                    <div className='h2em' />

                    {config.detectMobile.isMobile() ?
                        <div>
                            <MobileWarning />
                            <div className="h2em" />
                        </div>
                        : " "
                    }

                    We are now ready to install{' '}
                    <span className="boldLightBlue">
                        nodectl
                    </span>
                    {' '}on our {configurationState.instance} which will turn allow you to
                    administer your Constellation Tessellation validator <span className="boldWhite">NODE</span>
                    {' '}with ease.

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        This command line utility takes a lot of the complexity out our running and
                        administering your Node by automating a lot of the necessary processes.
                    </div>

                    <div className="h2em" />

                    <NodeAssumptions />

                    <div className="h2em" />

                    From your local system, log into your cloud {configurationState.instance}'s terminal
                    as <span className="boldLightBlue">root</span>.
                    <div className="h1em" />


                    <UpdateUpgrade snipFontSize=".6em" user="root" />


                    <div className="h2em" />
                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                    <h5>
                        <div className="boldRed">
                            MAKE SURE YOU USE THE CORRECT VERSION!&nbsp;&nbsp; THIS IS A TEST NETWORK, SO THE UPDATES
                            HAVE THE ABILITY INCREMENT FAST.
                        </div>
                    </h5>

                    <Button className="whiteOnBlackBoxShadow" onClick={() => {
                        window.open('https://github.com/netmet1/constellation_nodectl/releases/', '_blank');
                    }}>
                        <Tooltip title="This link will take you to Github to verify your release versioning">
                            <span>NODECTL RELEASES</span>
                        </Tooltip>
                    </Button>

                    <div className="h1em" />

                    In order to make sure we download the correct version of <span className="boldLightBlue">nodectl</span>
                    {' '}we need to figure out what architecture we are running on our {configurationState.instance}.

                    We can now pull down the latest release <Badge bg="primary">{config.versioning.currentVersion}</Badge>{' '}
                    from <span className="boldLightBlue">Constellation Network's</span> repository.
                    <div className="h1em" />
                    <div className="font-p6em">
                        <Badge bg="warning">REMINDER</Badge> <span className="boldLightBlue">sudo</span> will ask for a password.
                    </div>
                    <div className="hp5em" />

                    Lookup our architecture
                    <CodeSnippet snipFontSize=".6em" code="lscpu | grep Architecture" />

                    If we <span className="boldLightBlue">x86_64</span>; we know we have the 64-bit x86 instruction set on our {configurationState.instance}.
                    <CodeSnippet snipFontSize=".6em" bgColorClass="backgroundColorGray" code="Architecture: x86_64" />

                    If we <span className="boldLightBlue">aarch64 (arm_64)</span>; we know we have the ARM instruction set on our {configurationState.instance}.
                    <CodeSnippet snipFontSize=".6em" bgColorClass="backgroundColorGray" code="Architecture: aarch64" />

                    Download the <span className="boldLightBlue">nodectl</span>. Expand the correct section to reveal the command
                    to download the utility.

                    <div className="h1em" />


                    <Accordion>
                        <Accordion.Item variant="none" style={{ borderRadius: "4px" }}>
                            <Accordion.Button>
                                <span className="boldBlack">x86_64</span>&nbsp;download command, expand here.
                            </Accordion.Button>
                            <Accordion.Body>
                                <CodeSnippet code={"sudo wget https://github.com/netmet1/constellation_nodectl/releases/download/" + config.versioning.nodectlVersion + "/nodectl_x86_64 -P /usr/local/bin -O /usr/local/bin/nodectl; sudo chmod +x /usr/local/bin/nodectl"} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className="h1em" />

                    <Accordion>
                        <Accordion.Item variant="none" style={{ borderRadius: "4px" }}>
                            <Accordion.Button>
                                <span className="boldBlack">aarch64</span>&nbsp;download command, expand here.
                            </Accordion.Button>
                            <Accordion.Body>
                                <CodeSnippet code={"sudo wget https://github.com/netmet1/constellation_nodectl/releases/download/" + config.versioning.nodectlVersion + "/nodectl_arm64 -P /usr/local/bin -O /usr/local/bin/nodectl; sudo chmod +x /usr/local/bin/nodectl"} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <div className="h1em" />

                    Let's verify that our files are in place. Depending on your terminal application, the files should appear
                    in <Badge bg="success">GREEN</Badge>.{' '}
                    Make sure they are executable: &nbsp;<span className="boldLightBlue">-rwxr-xr-x</span>
                    <div className="font-p6em">
                        The 2 <Badge>l</Badge> are the letter <Badge>l</Badge> not the number #<Badge>1</Badge>  (ls -l)
                    </div>

                    <CodeSnippet snipFontSize=".6em" code="ls -l /usr/local/bin" />
                    Output should look similar (but not exactly) like <ImPointDown size={25} />.
                    <CodeSnippet snipFontSize=".6em" bgColorClass="backgroundColorGray" code="total 140568NEWLINE
                                [...] NEWLINE
                                -rwxr-xr-x 1 root root  8377368 Mar 29 19:35 nodectl NEWLINE
                                [...] NEWLINE
                            " />
                    <div className="h2em" />

                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />
                    <div className="h1em" />

                    Congratulations, <span className="boldLightGreen">nodectl</span> is installed!
                    <div className="h1em" />
                    We can now build our Node using <span className="boldLightGreen">nodectl</span>.
                    <div className="hp8em" />
                    <NextButtonCloudService nextLink="/nodectl/usage" nextName="AUTOMATED BUILD WITH NODECTL" skipNext={true} />
                </div>

                {/* <div className="h2em" />
                <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />

                <div className="textAlignLeftPad">
                    <NextButtonCloudService nextLink={nextLink} nextName={nextName} />
                </div> */}

                
            </div>

            <TopOfPageLink hash="#nodectlInstallTop" />

        </>

    );
}

export default NodeCtlInstall;








