import React from 'react'

import PageTitle from '../components/PageTitle'
import TopOfPageLink from '../components/TopOfPageLink.js'
import NextButtonCloudService from '../components/NextButtonCloudService.js'

function Welcome() {

    return (
        <>
            <div id="welcomeTop">
                <PageTitle title="WELCOME TO TESTNET 2.0" />
                <div className='h1em' />
                <div className="textAlignLeftPad w-65">

                    <div>
                        Node Garage is
                        proud and excited to welcome you
                        to Constellation Network's <span className="boldLightBlue">TestNet</span> project !
                    </div>

                    <div className='h1em' />

                    <div className='colorLightBlue'>
                        Project Code Name: <span className="boldWhite">Tessellation</span>
                    </div>

                    <div className='h1em' />

                    <div>
                        In order to control the progress and facilitate a
                        successful <span className="boldLightBlue">TestNet</span>, we have
                        created a limited space for dedicated Constellation Node Operators.
                        TestNet is currently <span className="boldLightBlue">invite</span> only.
                    </div>

                    <div className='h1em' />
                    <div className="colorLightBlue">
                        We appreciate your commitment and request that you please keep the processes,
                        instructions, and events; that are taking place on TestNet, private between TestNet Node Operators,
                        and do not advertised said information, to the public. This is only requested to
                        keep our TestNet development smooth and help make a clear path to
                        the <span className="boldWhite">MainNet 2.0</span> release.
                    </div>

                    <div className='h1em' />

                    <div>
                        When you are ready to continue, please checkout our the documentation link(s).
                    </div>

                    <div className='h1em' />

                    <NextButtonCloudService nextLink="/docs/doc-index"
                        nextName="CONSTELLATION DOCUMENT HUB"
                        skipNext={true}
                        butWidth="40vw"
                        center={true}
                        />
                    <div className="hp6em" />

                    <div className='h1em' />

                    <div className="colorLightBlue">
                        You can also choose any topic you would like from the
                        left side <span className="boldWhite">navigation</span> menu.
                    </div>

                </div>
                <TopOfPageLink hash="#welcomeTop" />
            </div>
        </>


    )
}

export default Welcome;