import React from 'react'
import CodeSnippet from './CodeSnippet';
import { ImPointDown } from 'react-icons/im';
import { Badge } from "react-bootstrap";

function UpdateUpgrade(props) {

    var { snipFontSize, user } = props

    if (snipFontSize === undefined) {
        snipFontSize = ".8em"
    }

    return (
        <>
            Bring our Node up to date
            <div className="font-p6em">
                (you can skip if you are continuing from the previous step.)
            </div>

            <CodeSnippet snipFontSize={snipFontSize} code="sudo apt -y update && sudo apt -y upgrade" />

            {user === "nodeadmin" &&
                <div>
                    Output will look similar to the <ImPointDown />.

                    You will be prompted for your <span className="boldLightBlue">nodeadmin</span> password.
                    <div className="font-p6em">
                        <Badge bg="warning">WARNING</Badge>: Your screen will not react and your password will not show as you type.
                    </div>

                    <CodeSnippet snipFontSize={snipFontSize} code="[sudo] password for nodeadmin:NEWLINE[...]" />
                </div>
            }

        </>
    )
}

export default UpdateUpgrade