import MobileWarning from "../../components/MobileWarning";
import { HashLink } from 'react-router-hash-link';

import { useConfig } from "../../utils/Configuration";
import CheckList from "../../components/CheckList";
import CheckListRow from "../../components/CheckListRow";

import NodeAssumptions from "../../components/NodeAssumptions";
import CodeSnippet from '../../components/CodeSnippet';
import PageTitle from "../../components/PageTitle";
import UpdateUpgrade from "../../components/UpdateUpgrade";
import TopOfPageLink from "../../components/TopOfPageLink";
import InstructionWithPic from "../../components/InstructionWithPic";
import NextButtonCloudService from "../../components/NextButtonCloudService";

import { Button, Badge, Accordion } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

import { ImPointDown, ImPointUp } from "react-icons/im";

import NodeCtlError from '../../images/nodectl-usage01.png';
import NodeCtlMenu from '../../images/nodectl-usage02.png';
import NodeCtlMenu9 from '../../images/nodectl-usage03.png';
import NodeCtlMenuIP from '../../images/nodectl-usage04.png';
import NodeCtlMenuY from '../../images/nodectl-usage05.png';
import NodeCtlDeps from '../../images/nodectl-usage06.png';
import NodeCtlUser from '../../images/nodectl-usage07.png';
import NodeCtlUserConfirm from '../../images/nodectl-usage08.png';
import NodeCtlPass from '../../images/nodectl-usage09.png';
import NodeCtlPass2 from '../../images/nodectl-usage10.png';
import NodeCtlSafePlace from '../../images/nodectl-usage11.png';
import NodeCtlNonRoot from '../../images/nodectl-usage12.png';
import NodeCtlBin from '../../images/nodectl-usage13.png';
import NodeCtlP12 from '../../images/nodectl-usage14.png';
import NodeCtlP12Alias from '../../images/nodectl-usage15.png';
import NodeCtlP12Pass1 from '../../images/nodectl-usage16.png';
import NodeCtlP12Pass2 from '../../images/nodectl-usage17.png';
import NodeCtlSafePlace2 from '../../images/nodectl-usage18.png';
import NodeCtlApi from '../../images/nodectl-usage19.png';
import NodeCtlJoin from '../../images/nodectl-usage20.png';
import NodeCtlConnecting from '../../images/nodectl-usage21.png';
import NodeCtlConnected from '../../images/nodectl-usage22.png';
import NodeCtlFinished from '../../images/nodectl-usage23.png';
import NodeCtlInstallStatus from '../../images/nodectl-usage24.png';


function NodeCtlUsage() {

    const config = useConfig()
    const configurationState = config.providerInfo

    const instruction1 = <div>
    </div>

    return (
        <>
            <div id="nodectlUsageTop">
                <PageTitle title="HOW TO USE NODECTL" type={1} />

                <div className='h1em' />

                <div className="textAlignLeftPad w-65">

                    <div className="font-p6em colorLightGreen textAlignCenter">
                        <u>Should Be Completed Already</u>
                    </div>

                    <div className="hp2em" />


                    <CheckList>
                        <CheckListRow listItem="Cloud Provider Signed Up" />
                        <CheckListRow listItem={"Created " + configurationState.instance + " on your Cloud Provider"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem={configurationState.instance + " is running"} />
                        <CheckListRow listItem={"You are able to successfully access your Node as your non-root user"} />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="p12 key file created" />
                        <CheckListRow listItem="Node package dependencies installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="node bash script created" />
                        <CheckListRow listItem="Tessellation package binaries installed" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="You are no longer using your root user" />
                        <CheckListRow listItem="Tessellation service created, enabled and started" />
                    </CheckList>
                    <CheckList>
                        <CheckListRow listItem="Your Node is successfully joined to the TestNet" />
                        <CheckListRow listItem="nodectl is installed" />
                    </CheckList>

                    <div className='h2em' />

                    {config.detectMobile.isMobile() ?
                        <div>
                            <MobileWarning />
                            <div className="h2em" />
                        </div>
                        : " "
                    }

                    After reviewing the checklist above, you should now have a working copy of{' '}
                    <span className="boldLightBlue">
                        nodectl
                    </span>
                    {' '}on our {configurationState.instance} which we can <span className="boldLightBlue">execute</span> to build and
                    administer your Constellation Tessellation validator <span className="boldWhite">NODE</span>
                    {' '}with ease.

                    <div className="h1em" />

                    <div className="colorLightBlue">
                        To repeat from the <span className="boldWhite">nodectl installation</span> instructions page. This command line utility takes a lot of the complexity out our running and
                        administering your Node by automating a lot of the necessary processes.
                    </div>

                    <div className="h2em" />

                    <NodeAssumptions />

                    <div className="h2em" />

                    From your local system, log into your cloud {configurationState.instance}'s terminal
                    as <span className="boldLightBlue">nodeadmin</span>.
                    <div className="h1em" />


                    <UpdateUpgrade snipFontSize=".6em" user="root" />

                    <div className="h2em" />


                    <div className="centeredFlexBoxCols">
                        <span className="font-p6em">Skip to the usage section</span>
                        <Button className="whiteOnBlackBoxShadow">
                            <HashLink smooth to="#nodectlUsageSection" style={{ textDecoration: 'none' }} >
                                <span className="boldWhite">NODECTL USAGE</span>
                            </HashLink>
                        </Button>
                    </div>

                    <div className="h2em" />

                    <PageTitle title="Install Tessellation on your Node with nodectl" type={2} />

                    <div className="h1em" />
                    In order to install <span className="boldLightBlue">Tessellation</span> on our Node, we will use
                    nodectl's {' '}
                    <Tooltip title="Command Line Interface - Graphical User Interface">
                        <span className="boldLightBlue">CLI-GUI</span>
                    </Tooltip>
                    {' '}which is a quasi-graphical user interface;
                    however, it is on the command-line interface, and you will not be able to use your <span className="colorLightBlue">mouse</span>
                    {' '}to navigate.  Instead we will use our <span className="boldLightBlue">keyboard only</span>.

                    <div className="h1em" />
                    <div className="colorLightBlue">
                        The CLI-GUI <span className="boldWhite">requires</span> that your screen be at <span className="boldWhite">least</span>
                        {' '}<span className="boldRed">36 lines</span> in height and <span className="boldRed">104 lines</span> in width.  To achieve
                        this, we simply will click on one corner of our <span className="boldWhite">PuTTy</span> window if we are using Windows, or
                        one corner of our <span className="boldWhite">Terminal Window</span> if we are using Linux or Apple.  Drag the window out
                        so that the window matches the required height and width.  If you do <span className="boldWhite">not</span> properly size you
                        PuTTy or terminal window, this is <span className="boldWhite">not a problem</span>. nodectl will <span className="boldOrange">error</span> out with a message that will allow you
                        figure out how much larger you need to make your window, to run the CLI-GUI.  <span className="boldWhite">Another</span> option is to
                        expand the window to <span className="boldWhite">max</span> to avoid having to worry about size.
                    </div>

                    <div className="h1em" />

                    <InstructionWithPic instruction=""
                        cardImageTitle="Error"
                        cardImageSubTitle="Example"
                        cardImageLocation={NodeCtlError}
                        cardImageAltText={"Enter Node ID option 1"}
                        mobileWidth={60}
                        desktopWidth={65}
                    />
                    <div className="h2em" />

                    Let's open up <span className="boldLightBlue">nodectl</span> and begin!  Remember what we discussed
                    about the <span className="boldRed">error</span> message above <ImPointUp size={25} />.

                    <div className="h1em" />

                    Execute <span className="boldLightBlue">nodectl</span> from the command line.
                    <CodeSnippet snipFontSize=".6em" code="sudo nodectl" />

                    <div className="h1em" />

                    <img src={NodeCtlMenu} alt="nodectl main menu" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Arrow down with your <span className="boldLightBlue">keyboard</span> to the menu option 9 <span className="boldLightBlue">INSTALL TESSELLATION</span>
                    {' '} or hit the <span className="boldLightBlue">9</span> key on your keyboard.
                    <div className="hp5em" />
                    <img src={NodeCtlMenu9} alt="nodectl option 9" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    A new menu will appear.  Wait while the system attempts to obtain your <span className="boldLightBlue">IP address</span> from
                    your {config.providerInfo.instance}.
                    <div className="hp5em" />
                    <img src={NodeCtlMenuIP} alt="nodectl obtain ip" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Once completed, you will be asked to confirm that you want to install <span className="boldLightBlue">Tessellation</span> 
                    {' '}on your {config.providerInfo.instance}.  <span className="boldRed">Important</span>, do not install Tessellation over
                    an existing Node.  If you have Tessellation already installed, you need to <span className="boldLightBlue">upgrade</span> instead.
                    <div className="h1em" />
                    Do <span className="boldRed">not</span> resize your <span className="boldLightBlue">terminal</span> or 
                    {' '}<span className="boldLightBlue">PuTTy</span> window, during the rest of this installation; otherwise, the program 
                    will crash the CLI-GUI.
                    <div className="hp5em" />
                    <img src={NodeCtlMenuY} alt="nodectl confirm" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    The system will begin by checking for and installing <span className="boldLightBlue">updates</span> and
                    {' '}<span className="boldLightBlue">dependencies</span> 
                    {' '}onto your {config.providerInfo.instance}.  Please exercise{' '}
                    <span className="boldOrange">patience</span> during this setup, some of the dependencies will take a little 
                    time to install.  
                    
                    <div className="h1em" />

                    In previous steps we already updated and upgraded our {config.providerInfo.instance} {' '}so the update portion will not take much time.  You
                    can sit back and relax until we reach the next prompt.
                    <div className="hp5em" />
                    <img src={NodeCtlDeps} alt="nodectl dependencies" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    It is an <span className="boldRed">anti-pattern</span> to run and administer your {config.providerInfo.instance} as
                    {' '}<span className="boldOrange">root</span>. You will be prompted to add a new 
                    {' '}<span className="boldLightBlue">user</span> that you will use moving forward as your
                    main user.  <span className="boldOrange">Note</span>, it is also a bad idea to use the default
                    {' '}<span className="boldLightBlue">ubuntu</span> user (AWS).
                    <div className="h1em" />
                    The suggestion (recommendation) of <span className="boldLightBlue">nodeadmin</span> is suggested in the {'[ ]'}
                    {' '}and is default.  If you hit enter, it will be used; otherwise, you can choose the name 
                    of the user you want to have created, by typing it in here.
                    <div className="hp5em" />
                    <img src={NodeCtlUser} alt="nodectl user" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Confirm you are <span className="boldLightGreen">happy</span> with the user name you choose.
                    <div className="hp5em" />
                    <img src={NodeCtlUserConfirm} alt="nodectl user confirm" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    We will be prompted to enter in a 
                    {' '}<span className="boldLightBlue">8</span> character   
                    {' '}<span className="boldLightBlue">minimum</span> password. Please do not use
                    a <span className="boldRed">$</span> in the password.  
                    <div className="h1em" />
                    This is a security issue so it is recommended to use <span className="boldLightBlue">special 
                    characters</span>, <span className="boldLightBlue">number</span>, 
                    {' '}<span className="boldLightBlue">upper-case</span>, 
                    and <span className="boldLightBlue">lower-case</span> letters.
                    <div className="h1em" />
                    <span className="boldOrange">Important</span>, you will not see your password as you enter
                    it, nor will you be able to see any keyboard reactions. It will seem like nothing is working
                    and nothing is happening.  This is a <span className="boldLightBlue">security measure</span>. A good trick here is to not look
                    at the screen while typing in your password.  When you complete your password, hit enter.
                    <div className="hp5em" />
                    <img src={NodeCtlPass} alt="nodectl pass 1" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Confirm the password.  <span className="boldOrange">Remember</span>, you will not see your password as you enter
                    it, nor will you be able to see any keyboard reactions.
                    <div className="hp5em" />
                    <img src={NodeCtlPass2} alt="nodectl pass 2" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Take a moment and place your <span className="boldLightBlue">nodeadmin</span> password in a safe and
                    secure place.  Either a <span className="boldLightBlue">USB Flash Drive</span>, 
                    {' '}<span className="boldLightBlue">password security program</span>, or <span className="boldLightBlue">other</span>
                    {' '}so that you do not forget.
                    <div className="hp5em" />
                    <img src={NodeCtlSafePlace} alt="nodectl save" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    As we mentioned before, it is an <span className="boldRed">anti-pattern</span> to use your 
                    {' '}<span className="boldLightBlue">root</span> user account to administer your box on a 
                    day-to-day basis.  We can add some extra security to our future <span className="boldLightBlue">Node</span>
                    {' '}by disabling <span className="boldLightBlue">SSH</span> access to your system.  This is 
                    reversible at any time, and <span className="boldLightBlue">nodectl</span> also has 
                    a <span className="boldOrange">hidden</span> command to re-enable and disable this 
                    security enhancement. Type <span className="boldLightBlue">Y</span> and hit enter.
                    <div className="hp5em" />
                    <img src={NodeCtlNonRoot} alt="nodectl non-root" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Relax for a moment as <span className="boldLightBlue">nodectl</span> downloads the necessary
                    binary (executable) files (<span className="colorLightBlue">jar files</span>) that will be
                    the core of your future <span className="boldLightBlue">Node</span>.
                    <div className="hp5em" />
                    <img src={NodeCtlBin} alt="nodectl binaries" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Our <span className="boldLightBlue">Node</span> will rely on a <span className="boldGreen">p12</span>
                    {' '}file to handle authentication and wallet services.  This is a special type of private key
                    file.  It is a very <span className="boldRed">important</span> part of how our 
                    Constellation Network Node will properly work.
                    <div className="h1em" />
                    <span className="boldLightBlue">nodectl</span> will now create this file for us, it just needs
                    some personalized information before the creation can complete.
                    <div className="h1em" />
                    First we need to give our <span className="boldLightBlue">p12</span> file a name.  This is
                    {' '}<span className="boldGreen">private</span> and no one else will see this name.  You do
                    {' '}<span className="boldOrange">not</span> want to expose this file to anyone.  The name
                    should be something related to what the p12 file is for. <span className="boldOrange">NOTE</span> 
                    {' '}you will want to save (copy) this file <span className="boldRed">off</span> your Node on
                    a <span className="boldLightBlue">cold</span> storage device (<span className="font-p8em"> not 
                    required, but recommended</span> ).
                    <div className="hp5em" />
                    <img src={NodeCtlP12} alt="nodectl p12 name" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    We will now supply our <span className="boldLightBlue">Node</span> with an alias name.  This is a
                    {' '}<span className="boldOrange">public</span> name, while on the 
                    {' '}<span className="boldLightBlue">HyperGraph</span> this alias may show up on lists, etc.
                    Use something fun and/or Constellation Space related here.
                    <img src={NodeCtlP12Alias} alt="nodectl p12 alias" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    We will be prompted to enter in a <span className="boldLightGreen">P12</span>{' '},
                    {' '}<span className="boldLightBlue">10</span> character   
                    {' '}<span className="boldLightBlue">minimum</span> passphrase. Please do not use
                    a <span className="boldRed">$</span> in the passphrase.  
                    <div className="h1em" />
                    This is a security issue so it is recommended to use <span className="boldLightBlue">special 
                    characters</span>, <span className="boldLightBlue">number</span>, 
                    {' '}<span className="boldLightBlue">upper-case</span>, 
                    and <span className="boldLightBlue">lower-case</span> letters.  If someone guesses this
                    passphrase, your access to the <span className="boldLightBlue">HyperGraph</span> can be compromised,
                    and your <span className="boldLightBlue">wallet</span> wallet can be compromised.
                    <div className="h1em" />
                    <span className="boldOrange">Important</span>, you will not see your passphrase as you enter
                    it, nor will you be able to see any keyboard reactions. It will seem like nothing is working
                    and nothing is happening.  This is a <span className="boldLightBlue">security measure</span>. A good trick here is to not look
                    at the screen while typing in your passphrase.  When you complete your passphrase, hit enter.
                    <div className="hp5em" />
                    <img src={NodeCtlP12Pass1} alt="nodectl p12 pass" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />
                    
                    Confirm the <span className="boldLightGreen">P12</span> passphrase.  <span className="boldOrange">Remember</span>, you 
                    will not see your passphrase as you enter it, nor will you be able to see any keyboard reactions.
                    <div className="hp5em" />
                    <img src={NodeCtlP12Pass2} alt="nodectl p12 pass verify" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Take a moment and place your <span className="boldLightGreen">P12</span> passphrase in a safe and
                    secure place.  Either a <span className="boldLightBlue">USB Flash Drive</span>, 
                    {' '}<span className="boldLightBlue">passphrase/password security program</span>, or <span className="boldLightBlue">other</span>
                    {' '}so that you do not forget.  This is an <span className="boldOrange">important</span> passphrase.
                    <div className="h1em" />
                    While you are doing this, you will notice your progress indicator will change to 
                    {' '}<span className="boldLightGreen">generating</span> briefly, as your <span className="boldLightBlue">P12</span>
                    {' '}file is created and stored on your {config.providerInfo.instance}, which is fast turning into 
                    a full <span className="boldLightGreen">Constellation Network Validator Node</span>!
                    <div className="hp5em" />
                    <img src={NodeCtlSafePlace2} alt="nodectl p12 save" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    <span className="boldLightBlue">nodectl</span> will build the <span className="boldLightBlue">Node Service</span>, 
                    {' '} in the background, <span className="boldLightBlue">start</span> the service, and then pause for a 
                    few seconds while it watches the local <span className="boldLightBlue">API</span> service to make sure it
                    is up and running, before continuing.  In previous steps, ( <span className="font-p8em colorLightBlue">see the checklist</span> ) you should have opened up your
                    instances firewall settings ( <span className="font-p8em colorLightBlue">ports 9000-9001, 9002, and 9003</span> ).
                    <div className="hp5em" />
                    <img src={NodeCtlApi} alt="nodectl p12 waiting on API" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    When the <span className="boldLightBlue">API</span> service is confirmed to be up and running, 
                    {' '}<span className="boldLightBlue">nodectl</span> will initiate the join process, to join the
                    current <span className="boldLightGreen">TestNet HyperGraph Network</span>!
                    <div className="hp5em" />
                    <img src={NodeCtlJoin} alt="nodectl Joining" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    You will be presented with a <span className="boldLightBlue">progress indicator</span>.  To avoid extra network traffic, the progress
                    indicator will update every <span className="boldLightBlue">~10</span> seconds.  The process
                    could take up to <span className="boldLightBlue">5</span> minutes to complete or timeout. You
                    will be able to watch your <span className="boldLightBlue">Node</span> as it learns about the other <span className="boldLightBlue">Nodes</span> on the network and makes
                    a connection with them.
                    <div className="hp5em" />
                    <img src={NodeCtlConnecting} alt="nodectl connecting" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Do <span className="boldOrange">not</span> be alarmed or concerned if your Node does <span className="boldOrange">not</span>
                    {' '}fully connect. As all <span className="boldLightBlue">HyperGraphs</span> are <span className="boldLightBlue">decentralized</span>, the consistency of the 
                    network is <span className="boldOrange">not</span> in our control.  There may be other Nodes that coming or going from 
                    the network, having issues, or any other reason not related to
                    your specific Node.  Missing <span className="boldLightBlue">1%</span> to <span className="boldLightBlue">2%</span> out 
                    of the total Nodes on the network, is <span className="boldLightGreen">OK</span>!
                    <div className="hp5em" />
                    <img src={NodeCtlConnected} alt="nodectl connected" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    <span className="boldLightGreen">CONGRATULATIONS!</span> Your {config.providerInfo.instance} is
                    now a <span className="boldLightBlue">Constellation Network Node</span>!
                    <div className="h1em" />
                    We can continue to look around at our Node now; however, it is important that we close down our
                    {' '}<span className="boldLightBlue">root</span> user SSH session, and reconnect as 
                    {' '}<span className="boldLightBlue">nodeadmin</span>. Moving forward, <span className="boldLightBlue">root</span>
                    {' '}access is disabled 
                    {' '}(<span className="colorOrange">{' '}<span className="font-p8em">unless you skipped the recommended step <ImPointUp size={18} /> above</span>{' '}</span>).
                    <div className="h1em" />
                    We will discuss how to safely make sure <span className="boldLightBlue">nodeadmin</span> is properly setup, a few steps <ImPointDown size={25} /> after the next step.
                    <div className="h1em" />
                    You can <span className="boldLightBlue">press any key</span> to return back to 
                    the <span className="boldLightBlue">Main Menu</span>.
                    <div className="hp5em" />
                    <img src={NodeCtlFinished} alt="nodectl finished" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Once back at the <span className="boldLightBlue">main menu</span> we can use our <span className="boldLightBlue">arrow</span>
                    {' '}keys or hit the <span className="boldLightBlue">1</span> to load up the <span className="boldLightBlue">status menu</span>.
                    Go ahead and review the status of your Node, and <span className="boldLightBlue">press any key</span> to return back to the main menu again.
                    <div className="hp5em" />
                    <img src={NodeCtlInstallStatus} alt="nodectl status" className="nodectlImages whiteOnWhiteBoxShadow" />

                    <div className="h2em" />

                    Once back at the <span className="boldLightBlue">main menu</span> we will hit use our <span className="boldLightBlue">arrow</span>
                    {' '}keys or hit the <span className="boldLightBlue">E</span> to exit back to the command line on our <span className="boldLightBlue">Node</span>.

                    <div className="h2em" />

                    <PageTitle title="Test new nodeadmin user access" type={2} />
                    <div className="h1em" />

                    An <span className="boldRed">important</span> step to take now, is to make sure our <span className="boldLightBlue">nodeadmin</span>
                    {' '}user is working.  We do <span className="boldOrange">not</span> want to <span className="boldRed">exit</span> out of this terminal window, until we verify
                    we have proper <span className="boldLightBlue">non-root</span> user access.

                    <div className="h1em" />

                    Open up a new <span className="boldLightBlue">terminal</span> (Apple or Linux) or <span className="boldLightBlue">PuTTy</span> session
                    (Windows), and test your new <span className="boldLightBlue">nodeadmin</span> access.  If you do not remember the procedure to do this,
                    simply access the link <ImPointDown size={25} /> below and <span className="boldOrange">substitute</span>{' '}
                    <span className="boldLightBlue">root</span> or <span className="boldLightBlue">nodeadmin</span> in all the steps.
                    <div className="hp5em" />
                    <NextButtonCloudService nextLink="/access-vm" nextName="Access our cloud instance" skipNext={true} />






















                    <div className="h2em" />
                    <hr style={{ color: "rgb(113, 148, 229)", height: ".2em", width: "72vw" }} />





                    <div className="h2em" />
                    <div id="nodectlUsageSection" />
                    <PageTitle title="nodectl usage" type={2} />
                    Documentation to explain the various features of <span className="boldLightBlue">nodectl</span> is coming soon!  In the
                    meantime, you can rely on the <span className="boldLightGreen">Discord</span> community.  Most <span className="boldLightBlue">Node Operators</span> are 
                    well versed on <span className="boldLightBlue">nodectl</span>{' '}and are ready and willing to 
                    help!  No <span className="boldLightGreen">questions</span> are <span className="boldRed">bad</span> questions.
                </div>
            </div>

            <TopOfPageLink hash="#nodectlUsageTop" />




        </>

    );
}

export default NodeCtlUsage;








