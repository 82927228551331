import { useState, useEffect, useRef } from 'react'
import { Button } from "react-bootstrap";

import LogoWithTitle from './LogoWithTitle';

import { useAuth } from "../utils/Auth";
import { useNavigate } from 'react-router-dom'

export default function Login() {

  const auth = useAuth();
  //const config = useConfig();

  const userField = useRef();

  const navigate = useNavigate();

  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')

  const [authReady, setAuthReady] = useState(false)
  const [validationReady, setValidationReady] = useState(false)
  const [tokenReady, setTokenReady] = useState(false)
  const [recordToken, setRecordToken] = useState(false)

  const handleClick = function (e) {
    e.preventDefault();

    if (e.target.id === "login") {
      auth.setLoggedIn({
        ...auth.loggedIn,
        discord: user,
        password: pass,
      }, setAuthReady(true));
    } else if (e.target.id === "cancel") {
      auth.logout().then(function () {
        navigate("/")
      })
    }

  }

  // Part 1
  useEffect(() => {
    if (authReady) {

      auth.authUser().then(function (response) {
        response = {
          ...response,
          discord: user,
        }
        auth.updateUser(response)
          .then(function () {
            auth.setAuthParams({
              password: pass,
              hash: response["hash"],
              salt: response["salt"],
            })
            // need to update with setCallBack function
            setValidationReady(true)
          });
      });
    }

    return () => {
      setTokenReady(false)
      setAuthReady(false)
      setValidationReady(false)
    }

  }, [authReady])


  // Part 2
  useEffect(() => {
    if (validationReady && auth.loggedIn.discord !== undefined) {

      auth.handleValidation().then(function (isValidated) {
        if (isValidated) {
          setTokenReady(true)
        }
      })
    }
  }, [validationReady])


  // Part 3
  useEffect(() => {
    if (auth.isValidated && tokenReady) {

      if (auth.isValidated) {
        auth.setSignInState(true)
        auth.handleToken({
          type: "m"
        }).then(function (response) {
          setRecordToken(true)
        })
      }
    }
  }, [tokenReady])

  // Part 4
  useEffect(() => {
    if (recordToken) {
      // clean up function
      setAuthReady(false)
      setValidationReady(false)
      setTokenReady(false)
      setRecordToken(false)
      navigate('/')
    }
  }, [recordToken])

  useEffect(() => {
    userField.current.focus();
  }, [])




  return (
    <>
      <div className="logoBackgroundWrap" />
      <div className="h8em" />
      <div className="whiteOnWhiteBoxShadow padding-p2-3em bringToFront fade-in windowLocation20p-neg">
        <LogoWithTitle text="LOG IN" fontClass="font2rem" logoHeight="1.5rem" />
        <div className="h1em" />
        <div className='d-flex justify-content-center flex-column'>
          <div className='font-p6em'>
            <span className="constellationBlue textShadowDarkBg pr-6">TestNet</span> DISCORD HANDLE
          </div>
          <div className='justify-content-center'>
            <input
              type='text'
              className="textAlignCenter font-p6em w-100"
              onChange={e => setUser(e.target.value)}
              value={user}
              ref={userField}
              required
            />
          </div>
        </div>
        <div className="h1rem" />
        <div className='d-flex justify-content-center flex-column'>
          <div className='font-p6em'>
            <span className="constellationBlue textShadowDarkBg pr-6">NodeGarage</span> PASSWORD
          </div>
          <div>
            <input
              type='password'
              className="textAlignCenter font-p6em w-100"
              onChange={e => setPass(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="h2rem" />
        <div className='d-flex justify-content-center flex-column'>
          <div>
            <Button id="login" className='btn btn-lg whiteOnWhiteBoxShadow'
              onClick={e => handleClick(e)}>LOGIN</Button>&nbsp;
            <Button id="cancel" className='btn btn-lg whiteOnWhiteBoxShadow' variant="danger"
              onClick={e => handleClick(e)}>CANCEL</Button>
          </div>

          {auth.isValidated === false ?
            <section>
              <div className="h2em" />
              <div className="loginFailure">
                <div>Invalid Login</div>
                <div>or Session Expired</div>
              </div>
            </section>
            :
            <section>
              <div className="h2em" />
              <div className="loginFailure">&nbsp;</div>
            </section>
          }

        </div>
      </div>

    </>
  )
}
