import React, { useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link';

import { Button, Accordion } from 'react-bootstrap'
import { useConfig } from '../utils/Configuration'
import { useNavigate } from 'react-router-dom'
import { buildLeftNavigationText } from '../utils/LeftNavElements';

function LeftSideNav() {
    const config = useConfig()
    const navigate = useNavigate()

    const [btnToggle, setBtnToggle] = useState(false)

    const buttonList = buildLeftNavigationText(config.detectMobile.isMobile())

    const handleNavigation = (target) => {
        config.setMenuSelection({
            title: target.name,
            link: target.id,
            menuStatus: config.subMenu
        }, navigate(target.id))
        setBtnToggle(btnToggle ? false : true)
    }

    
    const accordionStyle = {
        btnNavigate2: {
            backgroundColor: "#145098",
            color: "white",
        },
        btnCurrent2: {
            backgroundColor: "#5dc0de",
            color: "white",
        },
        btnXsl2: {
            padding: ".2rem 1rem",
            position: "relative",
            left: ".5rem",
            fontSize: ".68rem",
            width: "18vw",
            margin: ".2rem"
        },
        accordionButton: {
            backgroundColor: "#145098",
            color: "white",
            padding: ".2rem 1rem",
            position: "relative",
            left: ".5rem",
            fontSize: ".68rem",
            borderRadius: "4px",
            width: "20vw",
            height: "1.5rem"
        },
        accordionItem: {
            backgroundColor: "#000",
            width: "20vw",
            padding: "0",
            margin: "0"
        },
        accordionBody: {
            display: "flex",
            flexDirection: "column",
            justifyItems: "space-around",
            margin: ".5rem",
            padding: ".2rem",
            // -webkit-transition: all 0.5s ease-in-out;
            // -moz-transition: all 0.5s ease-in-out;
            // -ms-transition: all 0.5s ease-in-out;
            // transition: all 0.5s ease-in-out;
        }
    }


    const handleClick = (target) => {
        if (target.id.includes("do")) {
            config.populateProviderDetails(target.id)
        } else if (target.id.includes("aws")) {
            config.populateProviderDetails(target.id)
        } else if (target.id.includes("gcp")) {
            config.populateProviderDetails(target.id)
        } else if (target.id.includes("disclaimer")) {
            config.setDisclaimer({
                ...config.disclaimer,
                manualReview: true
            })
        }
        handleNavigation(target)
    }

    const dynSetSubMenu = (provider) => {

        const providers = ["dig", "aws", "gcp", "tess"]
        const index = providers.indexOf(provider)
        var setBol = true
        var setDur = 0

        if (index > -1) {
            // provider was called
            providers.splice(index, 1)
            setBol = config.subMenu[provider].expand ? false : true
            setDur = .8
        } else {
            // none provider button hit
            provider = providers.splice(0, 1)
            setBol = config.subMenu[provider].expand
            setDur = 0
        }

        config.setSubMenu({
            [provider]: {
                expand: setBol,
                active: true,
                duration: setDur
            },
            [providers[0]]: {
                ...config.subMenu[providers[0]],
                duration: 0
            },
            [providers[1]]: {
                ...config.subMenu[providers[1]],
                duration: 0
            },
            [providers[2]]: {
                ...config.subMenu[providers[2]],
                duration: 0
            }
        })

        setBtnToggle(btnToggle ? false : true)

    }

    const handleSubMenu = (target) => {
        if (target === "gcpAccordionBtn" || target === "awsAccordionBtn" || target === "digAccordionBtn" || target === "tessAccordionBtn") {
            dynSetSubMenu(target.replace("AccordionBtn", ""))
        }
        else {
            dynSetSubMenu("other")
        }
    }

    useEffect(() => {
    }, [btnToggle])


    function CategoryButton(props) {
        var { mainTitle, refKey, children, expandedKey } = props

        return (
            <div>

                <style type="text/css">
                    {`
                    .btn-xsl2 {
                        padding: .2rem 1rem;
                        position: relative;
                        right: .5rem;
                        font-size: .68rem;
                        width: 18vw;
                        margin: .2rem;
                    }
                    `}
                </style>

                <Accordion defaultActiveKey={[expandedKey]}>
                    <Accordion.Item variant="item" style={accordionStyle.accordionItem} eventKey={refKey}>
                        <Accordion.Button
                            data-id={refKey + "accordionBtn"}
                            variant="button" style={accordionStyle.accordionButton}
                            onClick={e => handleSubMenu(e.target.id)}
                        >
                            <div id={refKey + "AccordionBtn"} style={{ textAlign: 'center', width: '100vw' }}>{mainTitle}</div>
                        </Accordion.Button>
                        <Accordion.Body variant="body" style={accordionStyle.btnXsl2}>
                            {
                                Object.keys(children).map((subItem, j) =>
                                    <Button type="button"
                                        data-item={j + "key"}
                                        variant={subItem === config.menuSelection.title ? "current2" : "navigate2"}
                                        style={subItem === config.menuSelection.title ? accordionStyle.btnCurrent2 : accordionStyle.btnNavigate2}
                                        key={j}
                                        size="xsl2"
                                        name={subItem}
                                        id={children[subItem]}
                                        onClick={e => handleClick(e.target)}
                                    >
                                        {subItem}
                                    </Button>

                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>
        )
    }


    const hashButton = (item, i) => {
        return <HashLink smooth to={buttonList[item]}>
            <Button type="button"
                data-item={i + "key"}
                variant={item === config.menuSelection.title ? "current" : "navigate"}
                key="{i}"
                size="xsl"
                name={item}
                id={buttonList[item]}
                className="btn btn-sm w-20 navButColors"
                onClick={e => handleClick(e.target)}
            >
                {item}
            </Button>
        </HashLink>
    }

    const buildButtonType = (item, i) => {
        if (item.includes("spacer")) {
            return <div style={{ paddingTop: "3px" }}>
                <div className="hp2em"
                    style={{ backgroundColor: "#000", width: "100%", height: ".6rem" }} />
            </div>
        } else if (typeof buttonList[item] === "object") {
            return <CategoryButton
                mainTitle={item}
                refKey={buttonList[item][0]}
                expandedKey={config.subMenu[buttonList[item][0]].expand ? buttonList[item][0] : 0}
                children={buttonList[item][1]} />
        } else {
            return <div style={{ paddingLeft: "8px", height: "1.8rem" }}>
                {hashButton(item, i)}
            </div>
        }
    }

    const createButtons = Object.keys(buttonList).map((item, i) =>

        <div key={i + "div"}>
            <style type="text/css">
                {`
                    .btn-navigate {
                    background-color: #145098;
                    color: white;
                    }
        
                    .btn-xsl {
                    padding: .2rem .45rem;
                    font-size: .68rem;
                    }
                `}
                {`
                    .btn-current {
                    background-color: #5dc0de;
                    color: white;
                    }
        
                    .btn-xsl {
                    padding: .2rem .45rem;
                    font-size: .68rem;
                    }
                `}
            </style>

            {buildButtonType(item, i)}
            <div key={i + "div2"} className='hp2rem' />
        </div>
    );

    useEffect(() => {
        return
    }, [config.menuSelection])

    return (
        <>

            <div className="leftNavText">
                {createButtons}
            </div>
        </>
    )
}

export default LeftSideNav