// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../utils/Auth";
import { useConfig } from "../../../../utils/Configuration";

import { Button, Badge } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';

//import PageTitle from "../../../../components/PageTitle";
//import NextButtonCloudService from "../../../../components/NextButtonCloudService";
import TopOfPageLink from "../../../../components/TopOfPageLink";
//import CodeSnippet from "../../../../components/CodeSnippet";

import { ImPointDown } from 'react-icons/im';
// import { FiFrown } from 'react-icons/fi';
// import { MdKeyboardReturn } from 'react-icons/md';

import WinSCP01 from '../../../../images/WinScp-01.png';
import WinSCP02 from '../../../../images/WinScp-02.png';
import WinSCP03 from '../../../../images/WinScp-03.png';
import WinSCP03a from '../../../../images/WinScp-03a.png';
import WinSCP04 from '../../../../images/WinScp-04.png';
import WinSCP05 from '../../../../images/WinScp-05.png';
import WinSCP06 from '../../../../images/WinScp-06.png';
import WinSCP07 from '../../../../images/WinScp-07.png';
import WinSCP08 from '../../../../images/WinScp-08.png';
import WinSCP09 from '../../../../images/WinScp-09.png';
import WinSCP10 from '../../../../images/WinScp-10.png';
import WinSCP11 from '../../../../images/WinScp-11.png';
import WinSCP12 from '../../../../images/WinScp-12.png';
import WinSCP13 from '../../../../images/WinScp-13.png';
import WinSCP14 from '../../../../images/WinScp-14.png';
import WinSCP15 from '../../../../images/WinScp-15.png';
import WinSCP16 from '../../../../images/WinScp-16.png';



function DigitalOceanImageWin(props) {

    const config = useConfig()
    const { type } = props

    let nextLink = "/do/launch-droplet"
    let nextName = "Digital Ocean launch droplet"

    var startUser = "nodeadmin"
    var endUser = "root"
    var dragSide = "left"
    var dragStart = "right"

    if (type === "restore") {
        startUser = "root"
        endUser = "nodeadmin"
        dragSide = "right"
        dragStart = "left"
    }

    var directoryWarning = <span className="font-p6em boldRed">
        <div className="hp6em" />
        You will not be in the <span className="boldLightBlue">mnt</span>
        {' '} directory, instead the <span className="boldLightBlue">/{'<'}root{'>'}</span> directory to start.
    </span>

    return (
        <>
            <div id="winScpTop" className="tabInsideStyle" >

                {type === "backup" ?
                    <>
                        We need a program that will download the files that we want to backup
                        from our broken <span className="boldLightBlue">Droplet</span> to
                        our <span className="boldLightBlue">Windows</span> local system.  This tutorial will
                        be teaching this with <span className="boldLightBlue">WinSCP</span> which
                        is a <span className="boldLightBlue">great</span> and <span className="boldLightBlue">free</span>
                        {' '} application for Windows.

                        <div className="h1em" />

                        It is recommended to use <span className="boldLightBlue">Commander</span>
                        {' '}mode, when asked during setup.  This is up to you; however, this tutorial will be
                        using Commander mode.

                        <div className="h1em" />

                        You will also want to <span className="boldLightBlue">import</span> your PuTTy sessions
                        when the program requests.  This will import your <span className="boldLightBlue">Node's</span>
                        {' '} configuration and you can avoid setting that up.  The tutorial will assume you
                        imported your PuTTy sessions.

                        <div className="h1em" />

                        <Button className="whiteOnBlackBoxShadow" onClick={() => {
                            window.open("https://winscp.net/eng/index.php", "_blank")
                        }}>
                            <Tooltip title="This is just an option. Please use your app of choice.">
                                <span>Win_SCP ( windows recommended - free )</span>
                            </Tooltip>
                        </Button>
                    </>
                    : ""}

                <div className="h1em" />

                Open up <span className="boldLightBlue">WinSCP</span>

                <div className="h1em" />

                When we open up <span className="boldLightBlue">WinSCP</span> we should be
                greeted with a popup <span className="boldLightBlue">login</span> box automatically.
                If you do not see this box when you start up your WinSCP, then click on the
                {' '}<span className="boldLightBlue">New Session</span>.

                <div className='hp5em' />

                Select your <span className="boldLightBlue">Node</span> from the options.  This should
                have been imported for you; during installation, if you choose to import
                your <span className="boldLightBlue">PuTTy</span>
                {' '} session. If not, you will need to click on <span className="boldLightBlue">new site</span> and
                enter in all your credentials.

                <div className='hp5em' />

                Click on the <span className="boldLightBlue">Edit</span> button. Change the
                {' '}<span className="boldLightBlue">User name</span> field from <span className="boldLightBlue">{startUser}</span>
                {' '} to <span className="boldLightBlue">{endUser}</span>.

                <div className='hp5em' />

                Click the <span className="boldLightBlue">save</span>

                <div className='hp5em' />

                Click the <span className="boldLightBlue">Login</span> button.

                <div className='hp5em' />

                <img src={WinSCP01} alt="open winscp"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Click the <span className="boldLightBlue">Update</span> button when/if presented with
                the <span className="boldLightBlue">POTENTIAL SECURITY BREACH WARNING</span>.  Since we{' '}
                <span className="boldLightBlue">know</span> that we are the ones working on this Node and we are the ones
                that all the sudden are <span className="boldLightBlue">accessing</span> our Node in an odd way...
                It isn't a security breach!

                <div className='hp5em' />

                <img src={WinSCP02} alt="security breach"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                When you log in, use your <span className="boldLightBlue">ssh</span> key passphrase not your{' '}
                <span className="boldLightBlue">nodeadmin</span> password.

                <div className='h1em' />

                You should be able to <span className="boldLightBlue">login</span> at this point.  If you get
                an <span className="boldRed">error</span>,
                try again.  If you <span className="boldRed">cannot</span> access your Node after that, you
                may need to <span className="boldLightBlue">skip</span> past the backup procedure
                to the next section.

                <div className='hp5em' />

                If the box hi-lited in the image below <ImPointDown size={25} /> does not show <span className="boldLightBlue">{ type === "backup" ? "mnt" : "/root" }</span>
                {' '} click the dropdown box and select { type === "backup" ? "mnt" : "/root" }.

                <div className="hp5em" />

                <img src={type === "backup" ? WinSCP03 : WinSCP03a} alt="mnt/root dir"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Double Click the <span className="boldLightBlue">Home</span> on the right side
                of the commander window.
                {type === "restore" && directoryWarning}

                <div className='hp5em' />

                <img src={WinSCP04} alt="navigate winscp 1"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />


                <div className='h1em' />

                Double Click the <span className="boldLightBlue">nodeadmin</span> on the right side
                of the commander window.
                {type === "restore" && directoryWarning}

                <div className='hp5em' />

                <img src={WinSCP05} alt="navigate winscp 2"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Double Click the <span className="boldLightBlue">tessellation</span> on the right side
                of the commander window.
                {type === "restore" && directoryWarning}
                <div className='hp5em' />

                <img src={WinSCP06} alt="navigate winscp 3"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                You should now see your <span className="boldLightBlue">p12</span> file. Select it
                and drag it to the <span className="boldLightBlue">{dragSide}</span> side of the
                commander window.

                <div className='hp5em' />

                {type === "backup" ?
                    <>
                        You will notice that this tutorial has a <span className="boldLightBlue">tmp</span>
                        {' '}(temp) directory selected to drag this file, to on our local system.  You will
                        most probably <span className="boldLightBlue">not</span> have the same.

                        <div className='hp5em' />

                        You should navigate to a location on the your local system that best suits where you would like
                        to save this backup file.

                        <div className='hp5em' />

                        <img src={WinSCP10} alt="navigate winscp 4"
                            className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                        <div className='hp5em' />

                        <img src={WinSCP07} alt="navigate winscp 5"
                            className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                        <div className='h1em' />
                    </> : ""}

                After selecting and dragging the <span className="boldLightBlue">p12</span> file over
                to the <span className="boldLightBlue">{dragSide}</span> side.  You should see the file in
                both locations.

                <div className='hp5em' />

                <img src={WinSCP08} alt="navigate winscp 6"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                Single click on the <span className="boldLightBlue">directory back</span> button until
                you are back to the original location in the <span className="boldLightBlue">right</span>
                {' '}side windows where we started.

                {type === "restore" &&
                    <>
                        <div className='h1em' />
                        Since we should no longer have root access, we can stop at
                        the <span className="boldLightBlue">nodeadmin</span> home directory.
                    </>
                }
                <div className='hp5em' />

                <img src={WinSCP09} alt="navigate winscp 7"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                {type === "backup" ?
                    <>
                        Double click the <span className="boldLightBlue">usr</span> directory.

                        <div className='hp5em' />

                        <img src={WinSCP11} alt="navigate winscp 8"
                            className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                        <div className='h1em' />

                        Double click the <span className="boldLightBlue">local</span> directory.

                        <div className='hp5em' />

                        <img src={WinSCP12} alt="navigate winscp 9"
                            className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                        <div className='h1em' />

                        Double click the <span className="boldLightBlue">bin</span> directory.

                        <div className='hp5em' />

                        <img src={WinSCP13} alt="navigate winscp 10"
                            className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                        <div className='h1em' />

                        We should see the <span className="boldLightBlue">cn-node</span> file.  If you do not
                        see the <span className="boldLightBlue">cn-node</span> file, this is not a problem
                        it is good to have the file, but not a deal breaker.

                        <div className='hp5em' />

                        <img src={WinSCP14} alt="navigate winscp 11"
                            className="nodectlImagesHalfNoHover whiteOnWhiteBoxShadow" />

                        <div className='h1em' />
                    </> : " "}

                Drag the <span className="boldLightBlue">cn-node</span> file from the
                {' '}<span className="boldLightBlue">{dragStart}</span> side to the <span className="boldLightBlue">{dragSide}</span>.
                You should see the file populate in the <span className="boldLightBlue">{dragSide}</span> commander window.

                {type === "backup" &&
                    <>
                        <div className='hp5em' />
                        <img src={WinSCP15} alt="navigate winscp 12"
                            className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                    </>
                }

                <div className='h1em' />

                { type === "restore" && 
                <>
                We should now have our <span className="boldLightBlue">p12</span> file in our <span className="boldLightBlue">nodeadmin</span>
                {' '}tessellation directory.
                <div className="hp8em" />
                We should now have your <span className="boldLightBlue">cn-node</span> file in our <span className="boldLightBlue">nodeadmin</span>
                {' '}home directory.
                </>
                }
                <div className='h1em' />

                You can now <span className="boldLightBlue">X</span> out the <span className="boldLightBlue">WinSPC</span>
                {' '}application.  You can say <span className="boldLightBlue">OK</span> to save your settings.
                <div className="hp5em" />

                <img src={WinSCP16} alt="close winscp"
                    className="nodectlImagesNoHover whiteOnWhiteBoxShadow" />

                <div className='h1em' />

                You are <span className="boldLightGreen">done</span> and can continue to the next section.

                <TopOfPageLink hash="#winScpTop" />

            </div>
        </>

    );
}

export default DigitalOceanImageWin;








